import React, { useState, useEffect } from 'react';
import './StudentDeleter.css'; // CSS dosyasını import edin
import Search from './Search'; // Search bileşenini import edin

const API_URL = process.env.REACT_APP_API_URL || 'https://ankahizliokuma.com';
const API_CEBIR_COURSES_URL = "https://cebirmentalaritmetik.com/api/admin/course/cebir/all";
const API_COURSES_UPDATE = "https://ankahizliokuma.com/api/admin/course/update";
const API_COURSES_URL = "https://ankahizliokuma.com/api/admin/course/all";
const API_CEBIR_COURSES_UPDATE = "https://cebirmentalaritmetik.com/api/admin/course/cebir/update";

const StudentDeleter = () => {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [selectedBanStudent, setSelectedBanStudent] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [groups, setGroups] = useState([]);




    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(`${API_URL}/api/users/all`);
                const data = await response.json();

                // Öğrencileri alfabetik olarak sıralayın
                data.sort((a, b) => a.fullName.localeCompare(b.fullName, 'tr'));

                setStudents(data);
                setFilteredStudents(data);
            } catch (error) {
                console.error('Öğrenciler alınırken bir hata oluştu:', error);
            }
        };

        fetchStudents();
    }, []);

    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const [responseGroups, responseCebir] = await Promise.all([
                    fetch(API_COURSES_URL),
                    fetch(API_CEBIR_COURSES_URL),
                ]);

                const dataGroups = await responseGroups.json();
                const dataCebir = await responseCebir.json();

                const combinedGroups = [...dataGroups, ...dataCebir];

                const uniqueGroups = combinedGroups.map(group => ({
                    ...group,
                    uniqueKey: `${group.id}_${group.title}`,
                }));

                setGroups(uniqueGroups);
            } catch (error) {
                console.error('Gruplar alınırken bir hata oluştu:', error);
            }
        };

        fetchGroups();
    }, []);

    const handleStudentSelect = (student) => {
        setSelectedStudent(student);
    };

    const handleBanStudentSelect = (student) => {
        setSelectedBanStudent(student);
    }

    const fetchCebirCourses = async () => {
        try {
            const response = await fetch(API_CEBIR_COURSES_URL);
            if (!response.ok) {
                throw new Error('Cebir kursları alınırken bir hata oluştu');
            }
            return await response.json();
        } catch (error) {
            console.error('Cebir kursları alınırken hata oluştu:', error);
            return [];
        }
    };


    const handleBan = async () => {
        if (selectedBanStudent) {
            try {
                const id = Number(selectedBanStudent.id); // selectedBanStudent kullan
                console.log(`Yasaklama işlemi başlatıldı. Öğrenci ID: ${id}`);
    
                const cebirCourses = await fetchCebirCourses();
                console.log('Cebir kursları çekildi:', cebirCourses);
    
                // Öğrenci bilgilerini al
                const studentResponse = await fetch(`${API_URL}/api/users/${id}`);
                const studentData = await studentResponse.json();
    
                if (!studentResponse.ok) {
                    throw new Error('Öğrenci bilgileri alınırken bir hata oluştu.');
                }
    
                console.log('Öğrenci bilgileri alındı:', studentData);
    
                const studentGroups = studentData.section || [];
    
                // Öğrenciyi yasakla (banned = 1)
                console.log(`Öğrenci yasaklanıyor. API:${API_URL}/api/users/settings/admin/ban/${id}`);
                const banResponse = await fetch(`${API_URL}/api/users/settings/admin/ban/${id}`, {

                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ banned: 1 }),
                });
    
                if (!banResponse.ok) {
                    throw new Error('Öğrenci yasaklanırken bir hata oluştu.');
                }
    
                console.log(`Öğrenci başarıyla yasaklandı. ID: ${id}`);
    
                let localGroups = [...groups];
    
                // Öğrencinin kurslarının kontenjanlarını güncelle ve kursları sil
                if (studentGroups.length > 0) {
                    for (const groupTitle of studentGroups) {
                        const selectedGroupIndex = localGroups.findIndex(group => group.title === groupTitle);
                        if (selectedGroupIndex !== -1) {
                            const selectedGroup = localGroups[selectedGroupIndex];
                            const updatedStudentsCount = selectedGroup.students + 1; // Kontenjanı artır
    
                            const updatedGroup = { ...selectedGroup, students: updatedStudentsCount };
                            localGroups[selectedGroupIndex] = updatedGroup;
    
                            // Unique key oluştur (id + title + startDate)
                            const uniqueKey = `${selectedGroup.id}-${selectedGroup.title}-${selectedGroup.startDate}`;
    
                            // Cebir kurslarıyla karşılaştırma
                            const isCebir = cebirCourses.some(course => {
                                const cebirUniqueKey = `${course.id}-${course.title}-${course.startDate}`;
                                return uniqueKey === cebirUniqueKey;
                            });
    
                            // Hangi API'nin kullanılacağını loglayın
                            const selectedAPI = isCebir ? 'Cebir API' : 'Genel Kurs API';
                            console.log(`Grup güncelleniyor. Kurs: ${groupTitle}, Kullanılan API: ${selectedAPI}`);
    
                            await updateGroupStudents(selectedGroup.id, updatedGroup, isCebir);
                        }
                    }
                }
    
                // Öğrencinin rozetlerini sil (boş array yap)
            } catch (error) {
                console.error('Yasaklama işlemi sırasında bir hata oluştu:', error);
                setNotificationMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
                setNotificationType('error');
            } finally {
                setTimeout(() => {
                    setNotificationMessage('');
                    setNotificationType('');
                }, 3000);
            }
    
            setSelectedBanStudent(null); // Öğrenci yasaklandıktan sonra seçimi temizleyin
        }
    };
    



    const handleDelete = async () => {
        if (selectedStudent) {
            try {

                const id = Number(selectedStudent.id);
                const cebirCourses = await fetchCebirCourses();

                const studentResponse = await fetch(`${API_URL}/api/users/${id}`);
                const studentData = await studentResponse.json();

                if (!studentResponse.ok) {
                    throw new Error('Öğrenci bilgileri alınırken bir hata oluştu.');
                }

                const studentGroups = studentData.section || [];

                const response = await fetch(`${API_URL}/api/users/${id}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    const updatedStudents = students.filter(student => student.id !== selectedStudent.id);
                    setStudents(updatedStudents);
                    setFilteredStudents(updatedStudents);

                    let localGroups = [...groups];

                    if (studentGroups.length > 0) {
                        for (const groupTitle of studentGroups) {
                            const selectedGroupIndex = localGroups.findIndex(group => group.title === groupTitle);
                            if (selectedGroupIndex !== -1) {
                                const selectedGroup = localGroups[selectedGroupIndex];
                                const updatedStudentsCount = selectedGroup.students + 1;

                                const updatedGroup = { ...selectedGroup, students: updatedStudentsCount };
                                localGroups[selectedGroupIndex] = updatedGroup;

                                // Unique key oluştur (id + title + startDate)
                                const uniqueKey = `${selectedGroup.id}-${selectedGroup.title}-${selectedGroup.startDate}`;

                                // Cebir kurslarıyla karşılaştırma
                                const isCebir = cebirCourses.some(course => {
                                    const cebirUniqueKey = `${course.id}-${course.title}-${course.startDate}`;
                                    return uniqueKey === cebirUniqueKey;
                                });

                                await updateGroupStudents(selectedGroup.id, updatedGroup, isCebir);
                            }
                        }
                    }

                    setGroups(localGroups);
                    setNotificationMessage('Öğrenci başarıyla silindi!');
                    setNotificationType('success');
                } else {
                    const errorData = await response.json();
                    console.error("API Yanıt Hatası:", errorData);
                    setNotificationMessage(errorData.message || 'Öğrenci silinirken bir hata oluştu.');
                    setNotificationType('error');
                }
            } catch (error) {
                console.error('Hata:', error);
                setNotificationMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
                setNotificationType('error');
            } finally {
                setTimeout(() => {
                    setNotificationMessage('');
                    setNotificationType('');
                }, 3000);
            }

            setSelectedStudent(null);
        }
    };


    const updateGroupStudents = async (groupId, updatedGroup, isCebir = false) => {
        try {
            const { uniqueKey, ...updatedGroupWithoutUniqueKey } = updatedGroup;

            // Doğru endpoint'i seç ve logla
            const endpoint = isCebir
                ? `${API_CEBIR_COURSES_UPDATE}/${groupId}` // Cebir için endpoint
                : `${API_COURSES_UPDATE}/${groupId}`;      // Diğer kurslar için endpoint

            // Seçilen endpoint'i logla
            console.log('Kullanılan API endpoint:', endpoint);

            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(updatedGroupWithoutUniqueKey),
            });

            if (!response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    const errorData = await response.json();
                    console.error('API Yanıt Hatası (JSON):', errorData);
                } else {
                    const errorText = await response.text();
                    console.error('API Yanıt Hatası (HTML veya farklı format):', errorText);
                }
                throw new Error('Grup kontenjanı güncellenirken bir hata oluştu');
            }

            console.log('Grup başarıyla güncellendi:', updatedGroupWithoutUniqueKey);

        } catch (error) {
            console.error('Grup kontenjanı güncellenirken bir hata oluştu:', error);
        }
    };



    const handleSearch = (query) => {
        const lowercasedQuery = query.toLowerCase();
        const filtered = students.filter(student =>
            student.fullName.toLowerCase().startsWith(lowercasedQuery)
        );
        setFilteredStudents(filtered);
    };

    return (
        
        <div className="student-deleter">
             <h2>Öğrenci Sil</h2>
             <h3>Mevcut Öğrenci Sayısı: {filteredStudents.length}</h3> 
             <Search onSearch={handleSearch} />
        {filteredStudents.length > 0 ? (
            
            <ul className="deleter-student-list">
                {filteredStudents.map((student) => (
                  
                    <li key={student.id} className="student-list-item">
                        <span className="student-name">{student.fullName}</span>
                        <div className="button-group">
                            <button className="banned-btn" onClick={() => handleBanStudentSelect(student)}>Yasakla</button>
                            <button className="delete-btn" onClick={() => handleStudentSelect(student)}>Sil</button>
                        </div>
                    </li>
                ))}
            </ul>
        ) : (
            <p>Silinecek öğrenci bulunamadı.</p>
        )}
    
        {selectedStudent && (
            <div className="confirmation-dialog">
                <p>
                    <strong>{selectedStudent.fullName}</strong> isimli öğrenciyi silmek istediğinizden emin misiniz?
                </p>
                <button onClick={handleDelete}>Evet, Sil</button>
                <button onClick={() => setSelectedStudent(null)}>İptal</button>
            </div>
        )}
    
        {selectedBanStudent && (
            <div className="confirmation-dialog">
                <p>
                    <strong>{selectedBanStudent.fullName}</strong> isimli öğrenciyi yasaklamak istediğinizden emin misiniz?
                </p>
                <button onClick={handleBan}>Evet, Yasakla</button>
                <button onClick={() => setSelectedBanStudent(null)}>İptal</button>
            </div>
        )}
    </div>
    );
};

export default StudentDeleter;

