import React, { useState } from 'react';

import './styles/SpeedReading.css';
import { useNavigate } from 'react-router-dom';

const SpeedReading = () => {
  const [selectedClass, setSelectedClass] = useState('1. Sınıf');
  const [showAdvancedLevels, setShowAdvancedLevels] = useState(false);


  const navigate = useNavigate();

  const handleAdvancedClick = () => {
    setShowAdvancedLevels(!showAdvancedLevels);
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  const handleBeginnerclick = () => {
    navigate("/hızlı-okuma-temel")
  };

  return (
    <div className="speed-reading-container">
      <h1 className="speed-reading-container__title">Eğitim Programları</h1>
      <ul className="speed-reading-container__program-list">
        <li className="speed-reading-container__program-item" onClick={handleBeginnerclick}>
          Hızlı Okuma Temel Seviye
        </li>
        <li className="speed-reading-container__program-item" onClick={handleAdvancedClick}>
          Hızlı Okuma İleri Seviye
        </li>

        {showAdvancedLevels && (
          <div className="speed-reading-container__class-selection">
            <label htmlFor="class" className="speed-reading-container__label">Sınıf Seçimi:</label>
            <select
              id="class"
              value={selectedClass}
              onChange={handleClassChange}
              className="speed-reading-container__select"
            >
              <option value="2. Sınıf">2. Sınıf</option>
              <option value="3. Sınıf">3. Sınıf</option>
              <option value="4. Sınıf">4. Sınıf</option>
              <option value="5. Sınıf">5. Sınıf</option>
              <option value="6. Sınıf">6. Sınıf</option>
              <option value="7. Sınıf">7. Sınıf</option>
              <option value="8. Sınıf">8. Sınıf</option>
              <option value="9. Sınıf">9. Sınıf</option>
              <option value="10. Sınıf">10. Sınıf</option>
              <option value="11. Sınıf">11. Sınıf</option>
              <option value="12. Sınıf">12. Sınıf</option>
            </select>
          </div>
        )}
      </ul>
    </div>

  );
};


export default SpeedReading;
