import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../MetinPanel/style/MetinDuzenle.css'; // Yeni CSS dosyanızı import edin

const MetinDuzenle = () => {
  const navigate = useNavigate();

  // State for metin listesi, seçilen metin ve form inputları
  const [metinler, setMetinler] = useState([]);
  const [selectedMetinId, setSelectedMetinId] = useState('');
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');

  // Backend'den tüm metinleri almak için useEffect kullanıyoruz
  useEffect(() => {
    fetch('https://ankahizliokuma.com/api/texts/metin/all')
      .then((response) => response.json())
      .then((data) => {
        setMetinler(data); // Gelen verileri state'e set ediyoruz
      })
      .catch((error) => {
        console.error('Error fetching the texts:', error);
        toast.error('Metinler alınırken bir hata oluştu.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  // Seçilen metni state'e yükleme
  const handleMetinSelect = (e) => {
    const metinId = e.target.value;
    setSelectedMetinId(metinId);

    // Seçilen metni bulma ve form inputlarını doldurma
    const selectedMetin = metinler.find((metin) => metin.id === parseInt(metinId, 10));
    if (selectedMetin) {
      setTitle(selectedMetin.title);
      setDetail(selectedMetin.detail);
    } else {
      setTitle('');
      setDetail('');
    }
  };

  // Metin güncelleme işlemi
  const handleUpdate = () => {
    if (!selectedMetinId) {
      toast.error('Lütfen bir metin seçin.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const updateData = {
      title,
      detail,
    };

    // Backend'e güncellenmiş metni PUT etme
    fetch(`https://ankahizliokuma.com/api/texts/edit/${selectedMetinId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || 'Güncelleme başarısız oldu');
          });
        }
        return response.json();
      })
      .then(() => {
        toast.success('Metin başarıyla güncellendi!', {
          position: 'top-right',
          autoClose: 3000, // 3 saniye sonra kapanacak
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Metinleri yeniden çekerek güncellenmiş verileri al
        fetch('https://ankahizliokuma.com/api/texts/metin/all')
          .then((response) => response.json())
          .then((data) => {
            setMetinler(data);
          })
          .catch((error) => {
            console.error('Error fetching the texts:', error);
          });
      })
      .catch((error) => {
        console.error('Error updating the text:', error);
        toast.error(`Bir hata oluştu: ${error.message}`, {
          position: 'top-right',
          autoClose: 5000, // 5 saniye sonra kapanacak
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  // Metin silme işlemi
  const handleDelete = () => {
    if (!selectedMetinId) {
      toast.error('Lütfen bir metin seçin.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (window.confirm('Bu metni silmek istediğinizden emin misiniz?')) {
      // Backend'den metni silme
      fetch(`https://ankahizliokuma.com/api/texts/delete/${selectedMetinId}`, {
        method: 'DELETE',
      })
        .then((response) => {
          if (response.ok) {
            toast.success('Metin başarıyla silindi!', {
              position: 'top-right',
              autoClose: 3000, // 3 saniye sonra kapanacak
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            // Silme işleminden sonra listeyi güncellemek için metinleri yeniden çekme
            setMetinler(metinler.filter((metin) => metin.id !== parseInt(selectedMetinId, 10)));
            setSelectedMetinId('');
            setTitle('');
            setDetail('');
          } else {
            return response.json().then((errorData) => {
              throw new Error(errorData.message || 'Silme işlemi başarısız oldu');
            });
          }
        })
        .catch((error) => {
          console.error('Error deleting the text:', error);
          toast.error(`Bir hata oluştu: ${error.message}`, {
            position: 'top-right',
            autoClose: 5000, // 5 saniye sonra kapanacak
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  // Geri dönme işlemi
  const handleGeridon = () => {
    navigate('/admin/87a5a95cb3e25/panel');
  };

  return (
    <div className="metin-duzenle">
      <ToastContainer /> {/* ToastContainer ekleyin */}

      <h1 className="metin-duzenle__title">Metin Düzenle</h1>
      <div className="metin-duzenle__form">
        {/* Metin Seçimi için Dropdown */}
        <label className="metin-duzenle__label" htmlFor="metinSelect">Metin Seçiniz:</label>
        <select
          id="metinSelect"
          className="metin-duzenle__input"
          value={selectedMetinId}
          onChange={handleMetinSelect}
          required
        >
          <option value="">Metin Seçin</option>
          {metinler.map((metin) => (
            <option key={metin.id} value={metin.id}>
              {metin.title}
            </option>
          ))}
        </select>

        {/* Metin Başlığı */}
        <label className="metin-duzenle__label" htmlFor="metin">Başlık:</label>
        <input
          type="text"
          id="metin"
          className="metin-duzenle__input"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Başlık girin"
          required
        />

        {/* Metin Detayı */}
        <label className="metin-duzenle__label" htmlFor="text">Metin (Long Text):</label>
        <textarea
          id="text"
          className="metin-duzenle__textarea"
          value={detail}
          onChange={(e) => setDetail(e.target.value)}
          placeholder="Metni buraya girin"
          rows="10"
          required
        />

        <div className="metin-duzenle__button-group">
          <button className="metin-duzenle__submit-button" onClick={handleUpdate}>
            Metni Güncelle
          </button>
        </div>

        <div className="metin-duzenle__button-group">
          <button className="metin-duzenle__delete-button" onClick={handleDelete}>
            Metni Sil
          </button>
        </div>

        <div className="metin-duzenle__button-group">
          <button className="metin-duzenle__back-button" onClick={handleGeridon}>
            Geri Dön
          </button>
        </div>
      </div>
    </div>
  );
};

export default MetinDuzenle;
