import React, { useState, useEffect, useMemo } from 'react';
import './CourseList.css';
import Search from '../StudentPanel/Search';

const API_COURSES_URL = "https://ankahizliokuma.com/api/admin/course/all";
const API_STUDENTS_URL = "https://ankahizliokuma.com/api/users/all";
const API_CEBIR_COURSES_URL = "https://cebirmentalaritmetik.com/api/admin/course/cebir/all";

const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('tr-TR', options);
};

// Rozet eşleştirmesi ve sıralaması
const badgeMapping = {
    'H.O T.S': '/imgs/H.O.T.S.png',
    'H.O İ.S': '/imgs/H.O.İ.S.png',
    'H.T T.S': '/imgs/H.T.T.S.png',
    'H.T İ.S': '/imgs/H.T.İ.S.png',
    'M.A Toplama': '/imgs/M.AToplama.png',
    'M.A Çıkarma': '/imgs/M.AÇıkarma.png',
    'M.A Çarpma': '/imgs/M.AÇarpma.png',
    'M.A Bölme': '/imgs/M.ABölme.png',
    'Düşünce Eğitimi': '/imgs/D.E.png',
    'Dershane Eğitimi': '/imgs/Dershane.png',
};

const badgeOrder = [
    'H.O T.S',
    'H.O İ.S',
    'H.T T.S',
    'H.T İ.S',
    'Düşünce Eğitimi',
    'M.A Toplama',
    'M.A Çıkarma',
    'M.A Çarpma',
    'M.A Bölme',
    'Dershane Eğitimi',
];

const dershaneCourses = [
    'Dershane Eğitimi İlkokul',
    'Dershane Eğitimi Ortaokul',
    'Dershane Eğitimi Lise',
];

const CourseList = ({ title, onCourseSelect }) => {
    const [courses, setCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCourses, setFilteredCourses] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const [responseCourses, responseCebirCourses] = await Promise.all([
                    fetch(API_COURSES_URL),
                    fetch(API_CEBIR_COURSES_URL),
                ]);

                if (!responseCourses.ok || !responseCebirCourses.ok) {
                    throw new Error('Veri alınamadı.');
                }

                const dataCourses = await responseCourses.json();
                const dataCebirCourses = await responseCebirCourses.json();

                const allCourses = [...dataCourses, ...dataCebirCourses];

                // Benzersiz kurslar için id + title + startDate kombinasyonu kullanılarak uniqueKey oluşturuluyor
                const uniqueCourses = Array.from(new Map(allCourses.map(course =>
                    [`${course.id}-${course.title}-${course.startDate}`, course]
                )).values());

                uniqueCourses.sort((a, b) => a.title.localeCompare(b.title, 'tr'));

                setCourses(uniqueCourses);
                setFilteredCourses(uniqueCourses);
            } catch (error) {
                console.error('Kurslar alınırken bir hata oluştu:', error);
                setNotificationMessage('Kurslar yüklenirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000);
            }
        };

        fetchCourses();
    }, []);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(API_STUDENTS_URL);
                if (!response.ok) {
                    throw new Error('Veri alınamadı.');
                }
                const data = await response.json();
                data.sort((a, b) => a.fullName.localeCompare(b.fullName, 'tr'));

                setStudents(data);
            } catch (error) {
                console.error('Öğrenciler alınırken bir hata oluştu:', error);
                setNotificationMessage('Öğrenciler yüklenirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000);
            }
        };

        fetchStudents();
    }, []);

    // useMemo ile arama yapısını optimize ediyoruz
    const filteredAndSortedCourses = useMemo(() => {
        return courses
            .filter(course => {
                const searchLower = searchTerm.trim().toLowerCase();
                if (searchTerm.length === 0) return true;
                return course.title.trim().toLowerCase().startsWith(searchLower);
            })
            .sort((a, b) => a.title.localeCompare(b.title, 'tr'));
    }, [courses, searchTerm]);

    const handleCourseClick = (course) => {
        setSelectedCourse(selectedCourse === course ? null : course);
        setSelectedStudent(null); // Seçili kurs değiştiğinde seçili öğrenciyi sıfırla
    };

    const handleStudentClick = (student) => {
        setSelectedStudent(selectedStudent === student ? null : student);
    };

    const getOrderedBadges = (studentCourses) => {
        return badgeOrder.map((badgeKey) => {
            const badgeSrc = badgeMapping[badgeKey];

            if (badgeKey === 'Dershane Eğitimi') {
                const hasDershaneCourse = studentCourses.some(course => dershaneCourses.includes(course));
                if (hasDershaneCourse) {
                    return (
                        <img
                            key={badgeKey}
                            src={badgeSrc}
                            alt={`${badgeKey} Rozeti`}
                            className="badge-icon"
                        />
                    );
                } else {
                    return (
                        <div key={badgeKey} className="badge-placeholder"></div>
                    );
                }
            } else {
                if (studentCourses.includes(badgeKey)) {
                    return (
                        <img
                            key={badgeKey}
                            src={badgeSrc}
                            alt={`${badgeKey} Rozeti`}
                            className="badge-icon"
                        />
                    );
                } else {
                    return (
                        <div key={badgeKey} className="badge-placeholder"></div>
                    );
                }
            }
        });
    };

    return (
        <div className="course-list-container">
            <h3>Kayıtlı Kurslar: {filteredAndSortedCourses.length}</h3>
            <Search onSearch={(query) => setSearchTerm(query)} />
            {notificationMessage && (
                <div className={`notification ${notificationType}`}>{notificationMessage}</div>
            )}
            <ul className="course-list">
                {filteredAndSortedCourses.map(course => (
                    <li key={`${course.id}-${course.title}-${course.startDate}`} onClick={() => handleCourseClick(course)} className="course-list-item">
                        {course.title}
                        {selectedCourse === course && (
                            <div className="course-detail">
                                <h2>{course.title}</h2>
                                <p>Tarih: {formatDate(course.startDate)} - {formatDate(course.endDate)}</p>
                                <p>Saat: {course.startTime} - {course.endTime}</p>
                                <p>Mevcut Kontejan: {course.total}</p>
                                <p>Bilgi: {course.info}</p>
                                <h3>Bu kurstaki öğrenciler:</h3>
                                <ul>
                                    {students
                                        .filter(student =>
                                            Array.isArray(student.section) && student.section.includes(course.title)
                                        )
                                        .map(student => (
                                            <li
                                                key={student.id}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleStudentClick(student);
                                                }}
                                                className="student-list-item"
                                            >
                                                <div className="student-main-info">
                                                    <span className="student-name">{student.fullName}</span>
                                                    <div className="badges-container">
                                                        {getOrderedBadges(student.studentCourses)}
                                                    </div>
                                                </div>
                                                {selectedStudent === student && (
                                                    <div className="student-detail">
                                                        <p><strong>Ad:</strong> {student.fullName}</p>
                                                        <p><strong>Soyad:</strong> {student.familyFullName}</p>
                                                        <p><strong>İkinci Soyad:</strong> {student.family2FullName}</p>
                                                        <p><strong>Aile Telefonu:</strong> {student.familyPhone}</p>
                                                        <p><strong>Öğrenci Telefonu:</strong> {student.studentPhone}</p>
                                                        <p><strong>Sınıf:</strong> {student.studentClass}</p>
                                                        <p><strong>Şehir:</strong> {student.studentCities}</p>
                                                        <p><strong>İlçe:</strong> {student.studentDistrict}</p>
                                                        <p><strong>Öğrenci Saatleri:</strong> {student.studentShift}</p>
                                                        <p><strong>E-posta:</strong> {student.email}</p>
                                                        <p><strong>Doğum Tarihi:</strong> {new Date(student.studentBirthDate).toLocaleDateString()}</p>
                                                        <p><strong>Açıklama:</strong> {student.info}</p>
                                                        <p><strong>Öğrencinin Aldığı Kurslar:</strong> {student.studentCourses.join(', ')}</p>
                                                    </div>
                                                )}
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CourseList;
