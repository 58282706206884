import React, { useState, useEffect, useMemo } from 'react';
import './StudentCreate.css'; // CSS dosyanızı buraya ekleyin
const API_URL = process.env.REACT_APP_API_URL || 'https://ankahizliokuma.com';
const API_CEBIR_COURSES_URL = "https://cebirmentalaritmetik.com/api/admin/course/cebir/all";
const API_COURSES_UPDATE = "https://ankahizliokuma.com/api/admin/course/update";
const API_COURSES_URL = "https://ankahizliokuma.com/api/admin/course/all";
const API_CEBIR_COURSES_UPDATE = "https://cebirmentalaritmetik.com/api/admin/course/cebir/update";


const StudentCreate = () => {
    const [students, setStudents] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [groups, setGroups] = useState([]); // Gruplar için state

    const [form, setForm] = useState({
        fullName: '',
        familyFullName: '',
        family2FullName: '',
        familyPhone: '',
        studentPhone: '',
        studentClass: '',
        studentCities: '',
        studentDistrict: '',
        studentShift: '',
        email: '',
        studentBirthDate: '',
        password: '',
        info: '',
        selectedCourses: [], // Mevcut kurslar (checkbox'lı olanlar)
        section: '' // Seçilen grup adı
    });

    // 1. Grupları fetch etmek için useEffect
    useEffect(() => {
        const fetchGroups = async () => {
            try {
                const [responseGroups, responseCebir] = await Promise.all([
                    fetch(`${API_URL}/api/admin/course/all`),
                    fetch(API_CEBIR_COURSES_URL)
                ]);

                const dataGroups = await responseGroups.json();
                const dataCebir = await responseCebir.json();
                console.log("Diğer Kurslar:", dataGroups); // Diğer kursları loglayın
                console.log("Cebir Kursları:", dataCebir); // Cebir kurslarını loglayın

                // Normal kursları ve cebir kurslarını birleştiriyoruz
                const combinedGroups = [...dataGroups, ...dataCebir];

                // Yinelenen kursları filtreliyoruz (ID + Title benzersiz olmalı)
                const uniqueGroups = Array.from(new Map(combinedGroups.map(group =>
                    [`${group.id}-${group.title}-${group.startDate}`, group]
                )).values());



                setGroups(uniqueGroups);
                console.log("Unique:", uniqueGroups); // Kursları state'e kaydediyoruz
            } catch (error) {
                console.error('Kurslar alınırken bir hata oluştu:', error);
            }
        };

        fetchGroups();
    }, []);


    const handleChange = (e) => {
        const { name, value, options, type } = e.target;

        if (type === 'select-multiple') {
            // Eğer multiple seçim varsa, seçilen değerleri array olarak alıyoruz
            const selectedValues = Array.from(options)
                .filter(option => option.selected)
                .map(option => option.value);

            setForm({
                ...form,
                [name]: selectedValues, // Birden fazla değer olduğunda diziye kaydet
            });
        } else {
            // Diğer input alanları (text, email, vs.) için normal string güncellemesi yapılıyor
            setForm({
                ...form,
                [name]: value, // Diğer inputlar için normal value işlemi
            });
        }
    };

    const handleGroupChange = (e) => {
        const { options } = e.target;

        // Seçilen tüm kursları alıyoruz
        const selectedValues = Array.from(options)
            .filter(option => option.selected)  // Seçilen kursları filtreliyoruz
            .map(option => option.value);       // Seçilen kursların değerlerini (title) alıyoruz

        // Seçilen kursları form state'ine ekliyoruz
        setForm(prevForm => ({
            ...prevForm,
            section: selectedValues
        }));
    };

    // Mevcut kursları (checkbox'lı olanlar) işleme
    const allCourses = [
        "Gri",
        "Turkuaz",
        "Turuncu",
        "Siyah",
        "H.O T.S",
        "H.O İ.S",
        "H.T T.S",
        "H.T İ.S",
        "M.A Toplama",
        "M.A Çıkarma",
        "M.A Çarpma",
        "M.A Bölme",
        "Düşünce Eğitimi",
        "Dershane Eğitimi İlkokul",
        "Dershane Eğitimi Ortaokul",
        "Dershane Eğitimi Lise",
        "Matematik",
        "Fizik",
        "Kimya",
        "Biyoloji",
        "Sosyal Bilgiler",
        "Tarih",
        "Türk Dili ve Edebiyatı",
        "Fen Bilimleri",
        "Coğrafya",
        "İngilizce",
        // Add more courses as needed
    ];

    const handleCourseChange = (courseName) => {
        setForm(prev => {
            const selectedCourses = prev.selectedCourses.includes(courseName)
                ? prev.selectedCourses.filter(name => name !== courseName) // Remove course if already selected
                : [...prev.selectedCourses, courseName]; // Add course if not selected
            return { ...prev, selectedCourses };
        });
    };


    const fetchCebirCourses = async () => {
        try {
            const response = await fetch(API_CEBIR_COURSES_URL);
            if (!response.ok) {
                throw new Error('Cebir kursları alınırken bir hata oluştu');
            }
            return await response.json();
        } catch (error) {
            console.error('Cebir kursları alınırken hata oluştu:', error);
            return [];
        }
    };

    // Diğer kursları fetch eden fonksiyon
    const fetchOtherCourses = async () => {
        try {
            const response = await fetch(API_COURSES_URL);
            if (!response.ok) {
                throw new Error('Diğer kurslar alınırken bir hata oluştu');
            }
            return await response.json();
        } catch (error) {
            console.error('Diğer kurslar alınırken hata oluştu:', error);
            return [];
        }
    };

    const createUniqueKey = (id, title) => {
        return `${id}-${title.replace(/\s+/g, '-').toLowerCase()}`;
    };


    // 3. Form submit işleminde seçilen grubu işleme
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            // Formun 'section' alanı boşsa işlem yapmadan çık
            if (!form.section || form.section.length === 0) {
                setNotificationMessage('Lütfen en az bir grup seçin.');
                setNotificationType('error');
                return; // Fonksiyonu burada sonlandır
            }
    
            // Tüm kursları fetch edin
            const cebirCourses = await fetchCebirCourses();
            const otherCourses = await fetchOtherCourses();
    
            // Form verilerini hazırlama
            const { uniqueKey, ...submitDataWithoutUniqueKey } = form; // uniqueKey'yi formdan çıkarıyoruz
            const submitData = {
                ...submitDataWithoutUniqueKey,
                studentCourses: form.selectedCourses,
                section: form.section // Seçilen grup(lar)
            };
    
            console.log("Gönderilen veriler:", submitData); // Konsolda gönderilen veriyi kontrol edelim
    
            // Formu gönderme
            const response = await fetch(`${API_URL}/api/users/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(submitData), // uniqueKey'yi göndermiyoruz
            });
    
            if (response.ok) {
                const newStudent = await response.json();
                setStudents([...students, newStudent]);
    
                let localGroups = [...groups];
    
                // Grubun kontenjanını güncelleme
                if (form.section && form.section.length > 0) {
                    for (const groupTitle of form.section) {
                        const selectedGroupIndex = localGroups.findIndex(group => group.title === groupTitle);
                        if (selectedGroupIndex !== -1) {
                            const selectedGroup = localGroups[selectedGroupIndex];
                            const updatedStudents = selectedGroup.students - 1;
    
                            // Yerel groups kopyasını güncelle
                            const updatedGroup = { ...selectedGroup, students: updatedStudents };
                            localGroups[selectedGroupIndex] = updatedGroup;
    
                            // Benzersiz anahtar (uniqueKey) oluştur
                            const uniqueKey = createUniqueKey(selectedGroup.id, selectedGroup.title);
    
                            // Grubun cebir olup olmadığını kontrol et
                            const isCebir = cebirCourses.some(course => createUniqueKey(course.id, course.title) === uniqueKey);
    
                            // Grup türüne göre API endpoint'ine yönlendirme
                            await updateGroupStudents(selectedGroup.id, updatedGroup, isCebir);
                        }
                    }
                }
    
                // Tüm güncellemelerden sonra, groups state'ini güncelle
                setGroups(localGroups);
    
                // Formu sıfırlama
                setForm({
                    fullName: '',
                    familyFullName: '',
                    family2FullName: '',
                    familyPhone: '',
                    studentPhone: '',
                    studentClass: '',
                    studentCities: '',
                    studentDistrict: '',
                    studentShift: '',
                    email: '',
                    studentBirthDate: '',
                    password: '',
                    info: '',
                    selectedCourses: [],
                    section: []
                });
    
                setNotificationMessage('Öğrenci başarıyla eklendi!');
                setNotificationType('success');
    
            } else {
                const errorData = await response.json();
                console.error("API Yanıt Hatası:", errorData);  // API'den dönen hatayı konsola yazdır
                setNotificationMessage(errorData.message || 'Öğrenci eklenirken bir hata oluştu');
                setNotificationType('error');
            }
        } catch (error) {
            console.error("Fetch isteği hatası:", error); // Fetch isteğinden kaynaklanan hataları konsola yazdır
            setNotificationMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
            setNotificationType('error');
        } finally {
            setTimeout(() => {
                setNotificationMessage('');
                setNotificationType('');
            }, 3000);
        }
    };
    
    // Grubun kontenjanını güncelleyen fonksiyon
    const updateGroupStudents = async (groupId, updatedGroup, isCebir = false) => {
        try {
            // Remove 'uniqueKey' from the updated group object
            const { uniqueKey, ...updatedGroupWithoutUniqueKey } = updatedGroup;

            // Doğru endpoint'i seç
            const endpoint = isCebir
                ? `${API_CEBIR_COURSES_UPDATE}/${groupId}`
                : `${API_COURSES_UPDATE}/${groupId}`;

            console.log("Güncellenen grup verisi (uniqueKey olmadan):", updatedGroupWithoutUniqueKey);
            console.log("API endpoint:", endpoint); // Log the endpoint for debugging

            // JSON olarak veriyi gönder
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(updatedGroupWithoutUniqueKey),
            });

            if (!response.ok) {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf('application/json') !== -1) {
                    const errorData = await response.json();
                    console.error('API Yanıt Hatası (JSON):', errorData);
                } else {
                    const errorText = await response.text();
                    console.error('API Yanıt Hatası (HTML veya farklı format):', errorText);
                }
                throw new Error('Grup kontenjanı güncellenirken bir hata oluştu');
            }

            console.log('Grup başarıyla güncellendi:', updatedGroupWithoutUniqueKey);

        } catch (error) {
            console.error('Grup kontenjanı güncellenirken bir hata oluştu:', error);
        }
    };


    const filteredAndSortedGroups = useMemo(() => {
        return groups
            .filter(group => {
                const searchLower = searchTerm.trim().toLowerCase();
                if (searchTerm.length === 0) return true;
                return group.title.trim().toLowerCase().startsWith(searchLower);
            })
            .sort((a, b) => {
                const searchLower = searchTerm.toLowerCase();
                const aStartsWith = a.title.toLowerCase().startsWith(searchLower);
                const bStartsWith = b.title.toLowerCase().startsWith(searchLower);

                if (aStartsWith && !bStartsWith) return -1;
                if (!aStartsWith && bStartsWith) return 1;

                return a.title.localeCompare(b.title);
            });
    }, [groups, searchTerm]);





    return (
        <div className="create-register-container">
            <div className="create-register-form-container">
                <h1>Öğrenci Kayıt</h1>
                {notificationMessage && (
                    <div className={`create-notification ${notificationType}`}>
                        {notificationMessage}
                    </div>
                )}
                <form className="create-register-form" onSubmit={handleSubmit}>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={form.fullName}
                            onChange={handleChange}
                            placeholder="Öğrenci Adı Soyadı"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="familyFullName"
                            name="familyFullName"
                            value={form.familyFullName}
                            onChange={handleChange}
                            placeholder="Veli Adı Soyadı"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="family2FullName"
                            name="family2FullName"
                            value={form.family2FullName}
                            onChange={handleChange}
                            placeholder="İkinci Veli Adı Soyadı (İsteğe Bağlı)"
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="familyPhone"
                            name="familyPhone"
                            value={form.familyPhone}
                            onChange={handleChange}
                            placeholder="Veli Telefon Numarası"
                            required
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="text"
                            id="studentPhone"
                            name="studentPhone"
                            value={form.studentPhone}
                            onChange={handleChange}
                            placeholder="Öğrenci Telefon Numarası"
                        />
                    </div>
                    <div className="create-form-group">
                        <select
                            id="studentClass"
                            name="studentClass"
                            value={form.studentClass}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Öğrenci sınıfı seçiniz</option>
                            <option value="1.sınıf">1.sınıf</option>
                            <option value="2.sınıf">2.sınıf</option>
                            <option value="3.sınıf">3.sınıf</option>
                            <option value="4.sınıf">4.sınıf</option>
                            <option value="5.sınıf">5.sınıf</option>
                            <option value="6.sınıf">6.sınıf</option>
                            <option value="7.sınıf">7.sınıf</option>
                            <option value="8.sınıf">8.sınıf</option>
                            <option value="9.sınıf">9.sınıf</option>
                            <option value="10.sınıf">10.sınıf</option>
                            <option value="11.sınıf">11.sınıf</option>
                            <option value="12.sınıf">12.sınıf</option>
                            <option value="Mezun öğrenci">Mezun</option>
                            <option value="Üniversite öğrencisi">Üniversite öğrencisi</option>
                            <option value="Yetişkin">Yetişkin</option>
                        </select>
                    </div>
                    <section className="create-location-section">

                        <input
                            type='text'
                            id="city-select"
                            name="studentCities"
                            value={form.studentCities}
                            onChange={handleChange}
                            placeholder='Şehir Yazınız'
                            required
                        >

                        </input>


                        <input
                            type='text'
                            id="district-select"
                            name="studentDistrict"
                            value={form.studentDistrict}
                            onChange={handleChange}
                            placeholder='İlçe Yazınız'
                            required
                        >

                        </input>

                    </section>
                    <div className="create-form-group">
                        <select
                            id="studentShift"
                            name="studentShift"
                            value={form.studentShift}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled >Öğrenci okul saatleri</option>
                            <option value="Sabah">Sabahcı</option>
                            <option value="Öğlen">Öğlenci</option>
                            <option value="Tam Gün">Tam Gün</option>
                            <option value="Diğer">Diğer</option>
                        </select>
                    </div>
                    <div className="create-form-group">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            placeholder="E-posta"
                        /*required   şuanlık kapalı ilerde açılacak! */
                        />
                    </div>
                    <div className="create-form-group">
                        <input
                            type="date"
                            id="studentBirthDate"
                            name="studentBirthDate"
                            value={form.studentBirthDate}
                            onChange={handleChange}
                            placeholder="Öğrenci Doğum Tarihi"
                            required
                        />
                    </div>


                    <div className="create-form-group">
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                            placeholder="Şifre"

                        />
                    </div>

                    <div className="create-form-group">
                        <h3>Kurs Seçimi</h3>
                        {allCourses.map(course => (
                            <div key={course}>
                                <input
                                    type="checkbox"
                                    checked={form.selectedCourses.includes(course)} // Check if the course is selected
                                    onChange={() => handleCourseChange(course)}
                                />
                                <label>{course}</label>
                            </div>
                        ))}
                    </div>

                    <div className="form-group">
                        <h3>Grup Seçimi</h3>
                        <input
                            type="text"
                            placeholder="Grup ara (en az 1 karakter)..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}  // Arama terimi güncelleniyor
                            style={{ marginBottom: '10px', width: '100%' }}
                        />
                        <select
                            name="section"
                            style={{ height: "350px" }}
                            multiple
                            value={form.section}
                            onChange={handleGroupChange}
                            required
                        >
                            {
                                filteredAndSortedGroups.length > 0 ? (
                                    <>
                                        <option value="" disabled>Grup seçiniz</option>
                                        {filteredAndSortedGroups.map(group => (
                                            <option key={`${group.id}-${group.title}`} value={group.title}>
                                                {group.title}
                                            </option>
                                        ))}
                                    </>
                                ) : (
                                    <option value="" disabled>Kayıtlı kurs yok</option>
                                )
                            }
                        </select>
                    </div>


                    <div className="create-register-form-group">
                        <input
                            type="text"
                            id="acıklama"
                            name="info"
                            value={form.info}
                            onChange={handleChange}
                            placeholder="Acıklama"
                        />
                    </div>

                    <button type="submit" className="create-submit-button">Öğrenci Ekle</button>
                </form>
            </div>
        </div>
    );
};

export default StudentCreate;
