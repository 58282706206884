import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../MetinPanel/style/MetinEkle.css';

const MetinEkle = () => {
  const navigate = useNavigate();

  // State for form inputs
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');

  // Metin Başlığı ve Detayı
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleTextChange = (e) => {
    setDetail(e.target.value);
  };

  const handleGeridon = (e) => {
    navigate('/admin/87a5a95cb3e25/panel')
  };


  // Form gönderildiğinde backend'e metni gönderme
  const handleOnayla = () => {
    const postData = {
      title,
      detail,
    };

    // Backend'e metni POST etme
    fetch('https://ankahizliokuma.com/api/texts/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
      .then((response) => response.json())
      .then((data) => {
        alert('Metin başarıyla eklendi!');

        // Metin eklendikten sonra SoruEklePaneli'ne yönlendirme
        navigate(`/admin/soru-ekle/`);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    
    <div className="metin-paneli">
      
     
      <h1 className="metin-paneli__title">Metin Paneli</h1>
      <div className="metin-paneli__form">
        <label className="metin-paneli__label" htmlFor="metin">Başlık:</label>
        <input
          type="text"
          id="metin"
          className="metin-paneli__input"
          value={title}
          onChange={handleTitleChange}
          placeholder="Başlık girin"
          required
        />

        <label className="metin-paneli__label" htmlFor="text">Metin (Long Text):</label>
        <textarea
          id="text"
          className="metin-paneli__textarea"
          value={detail}
          onChange={handleTextChange}
          placeholder="Metni buraya girin"
          rows="10"
          required
        />

      <div className="metin-paneli__button-group">  
          <button className="metin-paneli__submit-button" onClick={handleOnayla}>
            Metni Onayla ve Soru Ekle
          </button>
       </div>   

        <div className="metin-paneli__button-group">
          <button className="metin-paneli__back-button" onClick={handleGeridon}>
            Geri Dön
          </button>
        </div>
      </div>
    </div>

  );
};

export default MetinEkle;
