import React, { useState, useEffect } from 'react';
import './styles/CebirCourseEditor.css'; // CSS dosyanızı buraya ekleyin
const API_CEBIR_COURSES_URL = "https://cebirmentalaritmetik.com/api/admin/course/cebir/all";
const API_CEBIR_URL = "https://cebirmentalaritmetik.com"

const CebirCourseEditor = () => {
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [form, setForm] = useState({
        title: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        total: '',
        students: '',
        info: ''
    });

    // Mevcut kursları backend'den çek
    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await fetch(API_CEBIR_COURSES_URL);
                const data = await response.json();
                setCourses(data);
            } catch (error) {
                console.error('Kurslar alınırken bir hata oluştu:', error);
            }
        };

        fetchCourses();
    }, []);

    // Formdaki değerleri değiştirme
    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    // Seçilen kursu formda düzenlenebilir hale getir
    const handleCourseSelect = (course) => {
        setSelectedCourse(course);
        setForm({
            title: course.title,
            startDate: course.startDate,
            endDate: course.endDate,
            startTime: course.startTime,
            endTime: course.endTime,
            total: course.total,
            students: course.students,
            info: course.info
        });
        setNotificationMessage('');
    };

    // Kursu güncelle
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedCourse) return;

        try {
            const response = await fetch(`${API_CEBIR_URL}/api/admin/course/cebir/update/${selectedCourse.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form)
            });

            if (response.ok) {
                const updatedCourse = await response.json();
                const updatedCourses = courses.map((course) =>
                    course.id === updatedCourse.id ? updatedCourse : course
                );
                setCourses(updatedCourses);
                setSelectedCourse(null); // Düzenleme bittiğinde seçimi temizle
                setForm({
                    title: '',
                    startDate: '',
                    endDate: '',
                    startTime: '',
                    endTime: '',
                    total: '',
                    students: '',
                    info: ''
                });
                setNotificationMessage('Kurs başarıyla güncellendi!');
                setNotificationType('success');
            } else {
                setNotificationMessage('Kurs güncellenirken bir hata oluştu.');
                setNotificationType('error');
            }
        } catch (error) {
            setNotificationMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
            setNotificationType('error');
        } finally {
            setTimeout(() => {
                setNotificationMessage('');
                setNotificationType('');
            }, 3000); // 3 saniye sonra notifikasyonu temizle
        }
    };


    return (
        <div className="course-editor">
            <h2>Cebir Kurs Düzenle</h2>

            {/* Notifikasyon Alanı */}
            {notificationMessage && (
                <div className={`notification ${notificationType}`}>
                    {notificationMessage}
                </div>
            )}

            {/* Kurs Seçimi */}
            <div className="course-selector">
                <h3>Kurs Seç:</h3>
                <ul>
                    {courses.map((course) => (
                        <li key={course.id} onClick={() => handleCourseSelect(course)}>
                            {course.title}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Seçilen kurs düzenleme formu */}
            {selectedCourse && (
                <form onSubmit={handleSubmit} className="course-form">
                    <label>
                        Grup İsmi:
                        <input
                            type="text"
                            name="title"
                            value={form.title}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Başlangıç Tarihi:
                        <input
                            type="date"
                            name="startDate"
                            value={form.startDate}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Bitiş Tarihi:
                        <input
                            type="date"
                            name="endDate"
                            value={form.endDate}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Başlangıç Saati:
                        <input
                            type="time"
                            name="startTime"
                            value={form.startTime}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Bitiş Saati:
                        <input
                            type="time"
                            name="endTime"
                            value={form.endTime}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Sınıf Kontejanı
                        <input
                            type="number"
                            name="total"
                            value={form.total}
                            onChange={handleChange}
                            required
                        />
                    </label>


                    <label>
                      Mevcut Kontejan
                        <input
                            type="number"
                            name="students"
                            value={form.students}
                            onChange={handleChange}
                            required
                        />
                    </label>


                    <label>
                        Bilgi:
                        <textarea
                            name="info"
                            value={form.info}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </label>
                    <button type="submit">Kursu Güncelle</button>
                </form>
            )}
        </div>
    );
};

export default CebirCourseEditor;
