import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Temelseviye.css';

const TemelSeviye = () => {
  const navigate = useNavigate();

  // State for storing fetched metinler (title and details)
  const [metinler, setMetinler] = useState([]);
  const [selectedMetinId, setSelectedMetinId] = useState(null);
  const [kelimeSayisi, setKelimeSayisi] = useState(1);
  const [sure, setSure] = useState(6);

  // Backend'den verileri çekmek için useEffect
  useEffect(() => {
    fetch('https://ankahizliokuma.com/api/texts/metin/all')
      .then((response) => response.json())
      .then((data) => {
        setMetinler(data);
        if (data.length > 0) {
          setSelectedMetinId(data[0].id); // Varsayılan olarak ilk metni seç
        }
      })
      .catch((error) => console.error('Veriler çekilirken hata oluştu:', error));
  }, []);

  // Metin seçimi değiştirme
  const handleMetinChange = (e) => {
    setSelectedMetinId(e.target.value);
  };

  // Kelime sayısı değiştirme
  const handleKelimeSayisiChange = (e) => {
    setKelimeSayisi(e.target.value);
  };

  // Süre değiştirme
  const handleSureChange = (e) => {
    setSure(e.target.value);
  };

  // Onayla butonuna tıklanınca
  const handleOnayla = () => {
    if (selectedMetinId) {
      navigate(`/temel-seviye-action/${selectedMetinId}`, {
        state: {
          kelimeSayisi: kelimeSayisi,   // Kullanıcının seçtiği kelime sayısı
          sure: sure                    // Kullanıcının seçtiği süre
        }
      });
    } else {
      alert("Lütfen bir metin seçin");
    }
  };

  const handleGeriDon = () => {
    navigate("/hızlı-okuma");
  };

    return (
      <div className="temelseviye-container">
        <h1 className="temelseviye-container__title">Metin Paneli</h1>
        <div className="temelseviye-container__form">
          <label htmlFor="metin" className="temelseviye-container__label">Metin Seçimi:</label>
          <select
            id="metin"
            value={selectedMetinId}
            onChange={handleMetinChange}
            className="temelseviye-container__select"
          >
            {metinler.map((metin) => (
              <option key={metin.id} value={metin.id} className="temelseviye-container__option">
                {metin.title}
              </option>
            ))}
          </select>
    
          <label htmlFor="kelimeSayisi" className="temelseviye-container__label">Kelime Sayısı:</label>
          <select
            id="kelimeSayisi"
            value={kelimeSayisi}
            onChange={handleKelimeSayisiChange}
            className="temelseviye-container__select"
          >
            {[1, 2, 3, 4, 5, 6].map((sayi) => (
              <option key={sayi} value={sayi} className="temelseviye-container__option">
                {sayi}
              </option>
            ))}
          </select>
    
          <label htmlFor="sure" className="temelseviye-container__label">Süre (saniye):</label>
          <select
            id="sure"
            value={sure}
            onChange={handleSureChange}
            className="temelseviye-container__select"
          >
            {[6, 5, 4, 3, 2, 1, 0.8, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1].map((saniye) => (
              <option key={saniye} value={saniye} className="temelseviye-container__option">
                {saniye} saniye
              </option>
            ))}
          </select>
    
          <div className="temelseviye-container__button-group">
            <button className="temelseviye-container__button temelseviye-container__button--submit" onClick={handleOnayla}>Onayla</button>
            <button className="temelseviye-container__button temelseviye-container__button--back" onClick={handleGeriDon}>Geri Dön</button>
          </div>
        </div>
      </div>
    );
    
};

export default TemelSeviye;
