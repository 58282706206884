// Question.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './Question.css'; // Özelleştirilmiş CSS dosyanız

const Question = () => {
  const { metinId } = useParams();
  const navigate = useNavigate(); // Yönlendirme için hook
  const [questions, setQuestions] = useState([]); // Sorular
  const [selectedOptionId, setSelectedOptionId] = useState(null); // Seçilen seçenek
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0); // Mevcut soru indeksi
  const [answers, setAnswers] = useState([]); // Kullanıcı cevapları
  const [showResults, setShowResults] = useState(false); // Sonuç ekranını göster
  const [showNotification, setShowNotification] = useState(false); // Bildirim göster
  const [openEndedAnswer, setOpenEndedAnswer] = useState(''); // Açık uçlu cevap
  const [isMultipleChoiceFinished, setIsMultipleChoiceFinished] = useState(false); // Çoktan seçmeli sorular bitti mi?
  const [isTestCompleted, setIsTestCompleted] = useState(false); // Test tamamlandı mı?

  useEffect(() => {
    if (!metinId) {
      console.error('metinId is undefined in useParams!');
      return;
    }

    // API'den soruları ve seçenekleri al
    fetch(`https://ankahizliokuma.com/api/question/metin/${metinId}`)
      .then(response => response.json())
      .then(data => {
        const multipleChoiceQuestions = data.filter(q => q.type === 'MULTIPLE_CHOICE');
        setQuestions(multipleChoiceQuestions); // Önce çoktan seçmeli soruları yükle
        setSelectedQuestionIndex(0); // İlk soru
        setAnswers(new Array(multipleChoiceQuestions.length).fill(null)); // Cevapları başlat
        setShowNotification(true); // Bildirimi göster
      })
      .catch(error => console.error('Error fetching questions:', error));
  }, [metinId]);

  // Seçenek seçimini yönetir (çoktan seçmeli sorular için)
  const handleOptionSelect = (optionId) => {
    setSelectedOptionId(optionId);
  };

  // Cevap gönderme fonksiyonu
  const handleSubmitAnswer = () => {
    const currentQuestion = questions[selectedQuestionIndex];
    let userAnswer;
    let isCorrect = null; // Başlangıçta null
    let isSkipped = false;

    if (currentQuestion.type === 'MULTIPLE_CHOICE') {
      if (selectedOptionId) {
        const correctOption = currentQuestion.options.find(option => option.correct);
        isCorrect = selectedOptionId === correctOption?.id;
        userAnswer = currentQuestion.options.find(option => option.id === selectedOptionId)?.optionText || '';
      } else {
        isSkipped = true;
        userAnswer = ''; // Boş cevap
      }
    }

    // Cevabı güncelle
    const updatedAnswers = [...answers];
    updatedAnswers[selectedQuestionIndex] = {
      question: currentQuestion.question,
      isCorrect,
      isSkipped,
      userAnswer,
      correctAnswer: currentQuestion.type === 'MULTIPLE_CHOICE' ? (currentQuestion.options.find(option => option.correct)?.optionText || 'Doğru cevap belirtilmemiş') : 'N/A',
    };
    setAnswers(updatedAnswers);

    // Sonraki soruya geç veya sonuçları göster
    if (selectedQuestionIndex < questions.length - 1) {
      setSelectedQuestionIndex(selectedQuestionIndex + 1);
      setSelectedOptionId(null);
    } else {
      setShowResults(true);
    }
  };

  // Atla butonunu yönetir
  const handleSkip = () => {
    const currentQuestion = questions[selectedQuestionIndex];
    let userAnswer = '';

    // Cevabı atlandı olarak güncelle
    const updatedAnswers = [...answers];
    updatedAnswers[selectedQuestionIndex] = {
      question: currentQuestion.question,
      isCorrect: null,
      isSkipped: true,
      userAnswer,
      correctAnswer: currentQuestion.type === 'MULTIPLE_CHOICE' ? (currentQuestion.options.find(option => option.correct)?.optionText || 'Doğru cevap belirtilmemiş') : 'N/A',
    };
    setAnswers(updatedAnswers);

    // Sonraki soruya geç veya sonuçları göster
    if (selectedQuestionIndex < questions.length - 1) {
      setSelectedQuestionIndex(selectedQuestionIndex + 1);
      setSelectedOptionId(null);
    } else {
      setShowResults(true);
    }
  };

  // Geri butonunu yönetir
  const handleBack = () => {
    if (selectedQuestionIndex > 0) {
      setSelectedQuestionIndex(selectedQuestionIndex - 1);
      const previousAnswer = answers[selectedQuestionIndex - 1];
      if (previousAnswer) {
        const prevOption = questions[selectedQuestionIndex - 1].options?.find(option => option.optionText === previousAnswer.userAnswer);
        if (prevOption) {
          setSelectedOptionId(prevOption.id);
        }
        setOpenEndedAnswer(previousAnswer.userAnswer || '');
      }
    }
  };

  // Açık uçlu sorulara geçiş
  const handleProceedToOpenEnded = () => {
    // Açık uçlu soruları getir
    fetch(`https://ankahizliokuma.com/api/question/metin/${metinId}`)
      .then(response => response.json())
      .then(data => {
        const openEndedQuestions = data.filter(q => q.type === 'OPEN_ENDED');
        setQuestions(openEndedQuestions);
        setSelectedQuestionIndex(0);
        setAnswers(new Array(openEndedQuestions.length).fill(null)); // Cevapları başlat
        setIsMultipleChoiceFinished(true); // Çoktan seçmeli sorular bitti
        setShowResults(false); // Açık uçlu sorular için sonuçları gizle
        setShowNotification(true); // Bildirimi göster
      })
      .catch(error => console.error('Error fetching open-ended questions:', error));
  };

  // Testi bitirme butonunu yönetir
  const handleFinishTest = () => {
    setIsTestCompleted(true);
  };

  // Anasayfaya dön butonunu yönetir
  const handleGoHome = () => {
    navigate('/'); // Anasayfaya yönlendir (gerekirse yolu ayarlayın)
  };

  // Sorular henüz yüklenmediyse
  if (questions.length === 0 && !isTestCompleted) {
    return <p className="loading-text">Sorular yükleniyor...</p>;
  }

  // Test tamamlandıysa
  if (isTestCompleted) {
    return (
      <div className="custom-question-page">
        <div className="custom-completion">
          <h2>Tebrikler, bütün testi bitirdiniz!</h2>
          <button onClick={handleGoHome} className="custom-home-button">
            Anasayfaya Dön
          </button>
        </div>
      </div>
    );
  }

  // Bildirim gösteriliyorsa
  if (showNotification) {
    return (
      <div className="custom-question-page">
        <div className="custom-notification">
          {isMultipleChoiceFinished ? (
            <>
              <h2>Tebrikler, çoktan seçmeli soruları bitirdiniz!</h2>
              <p>Şimdi açık uçlu sorular gelecek, hazır olun.</p>
              <p>Hazır olduğunuzda "Hazırım" butonuna tıklayın.</p>
              <button onClick={() => setShowNotification(false)} className="custom-ready-button">
                Hazırım
              </button>
            </>
          ) : (
            <>
              <h2>Tebrikler, metni bitirdiniz!</h2>
              <p>Şimdi çoktan seçmeli sorular gelecek, hazır olun.</p>
              <p>Hazır olduğunuzda "Hazırım" butonuna tıklayın.</p>
              <button onClick={() => setShowNotification(false)} className="custom-ready-button">
                Hazırım
              </button>
            </>
          )}
        </div>
      </div>
    );
  }

  // Mevcut fazı belirle (çoktan seçmeli veya açık uçlu)
  const isOpenEndedPhase = isMultipleChoiceFinished;

  return (
    <div className="custom-question-page">
      {showResults ? (
        <div className="custom-results">
          <h2>Sonuçlar</h2>
          <div className="results-summary">
            <p>Toplam Soru: {answers.length}</p>
            <p>Doğru Cevap: {answers.filter(ans => ans && ans.isCorrect === true).length}</p>
            <p>Yanlış Cevap: {answers.filter(ans => ans && ans.isCorrect === false).length}</p>
            <p>Atlanan Soru: {answers.filter(ans => ans && ans.isSkipped === true).length}</p>
          </div>
          <div className="results-details">
            {answers.map((result, index) => (
              result ? (
                <div key={index} className={`custom-result-card ${
                  result.isCorrect === true ? 'correct' :
                  result.isCorrect === false ? 'wrong' :
                  result.isSkipped ? 'skipped' : 'open-ended'
                }`}>
                  <div className="result-header">
                    <span className="question-number">{index + 1}.</span>
                    <span className="question-text">{result.question}</span>
                    <span className={`result-icon ${
                      result.isCorrect === true ? 'correct' :
                      result.isCorrect === false ? 'wrong' :
                      result.isSkipped ? 'skipped' : 'open-ended'
                    }`}>
                      {result.isCorrect === true ? <i className="fa fa-check-circle"></i> :
                        result.isCorrect === false ? <i className="fa fa-times-circle"></i> :
                        result.isSkipped ? <i className="fa fa-minus-circle"></i> :
                        <i className="fa fa-comment"></i>}
                    </span>
                  </div>
                  <div className="correct-answer-info">
                    <strong>Doğru Cevap: {result.correctAnswer || 'Doğru cevap belirtilmemiş'}</strong>
                  </div>
                  <div className={`user-answer-info ${
                    result.isCorrect === true ? 'correct' :
                    result.isCorrect === false ? 'wrong' :
                    result.isSkipped ? 'skipped' : 'open-ended'
                  }`}>
                    {result.isCorrect === true ? (
                      <span>Cevabın doğru</span>
                    ) : result.isCorrect === false ? (
                      <>
                        <strong>Senin Cevabın: {result.userAnswer}</strong>
                      </>
                    ) : result.isSkipped ? (
                      <span>Cevaplanmadı</span>
                    ) : (
                      <>
                        <strong>Cevabınız:</strong> {/* Açık uçlu cevaplar için placeholder */}
                      </>
                    )}
                  </div>
                </div>
              ) : null
            ))}
          </div>
          {isMultipleChoiceFinished ? (
            <button onClick={() => window.location.reload()} className="custom-restart-button">
              Yeniden Başla
            </button>
          ) : (
            <button onClick={handleProceedToOpenEnded} className="custom-next-button">
              Açık Uçlu Sorulara Geç
            </button>
          )}
        </div>
      ) : isOpenEndedPhase ? (
        <div className="open-ended-section">
          <h2>Açık Uçlu Sorular</h2>
          <div className="open-ended-questions">
            {questions.map((question, index) => (
              <div key={index} className="custom-result-card open-ended-question">
                <div className="result-header">
                  <span className="question-number">{index + 1}.</span>
                  <span className="question-text">{question.question}</span>
                </div>
                {/* Açık uçlu sorular için bir textarea ekleyebilirsiniz */}
              </div>
            ))}
          </div>
          <button onClick={handleFinishTest} className="custom-finish-button">
            Testi Bitir
          </button>
        </div>
      ) : (
        <>
          <h2 className="custom-question-title">{questions[selectedQuestionIndex].question}</h2>
          {questions[selectedQuestionIndex].type === 'MULTIPLE_CHOICE' && (
            <div className="custom-options-container">
              {questions[selectedQuestionIndex].options.map((option, index) => (
                <div key={option.id} className="custom-option">
                  <span className="custom-option-letter">{['A', 'B', 'C', 'D'][index]} )</span>
                  <input
                    type="radio"
                    className="option-radio"
                    name="option"
                    id={`custom-option-${option.id}`}
                    checked={selectedOptionId === option.id}
                    onChange={() => handleOptionSelect(option.id)}
                  />
                  <label htmlFor={`custom-option-${option.id}`}>{option.optionText}</label>
                </div>
              ))}
            </div>
          )}
          <div className="custom-navigation-buttons">
            <button onClick={handleBack} className="custom-back-button" disabled={selectedQuestionIndex === 0}>
              Geri
            </button>
            <button onClick={handleSubmitAnswer} className="custom-submit-button">
              {selectedQuestionIndex < questions.length - 1 ? "Cevabı Gönder ve Geç" : "Sonucu Gör"}
            </button>
            <button onClick={handleSkip} className="custom-skip-button">
              Atla
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Question;
