import React, { useState, useEffect } from 'react';
import "./StudentEditor.css"; // CSS dosyanızı buraya ekleyin
import Search from './Search';
const API_URL = process.env.REACT_APP_API_URL || 'https://ankahizliokuma.com';
const API_CEBIR_COURSES_URL = "https://cebirmentalaritmetik.com/api/admin/course/cebir/all";
const API_CEBIR_COURSES_UPDATE = "https://cebirmentalaritmetik.com/api/admin/course/cebir/update";

const StudentEditor = () => {
    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [groups, setGroups] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [form, setForm] = useState({
        fullName: '',
        familyFullName: '',
        family2FullName: '',
        familyPhone: '',
        studentPhone: '',
        studentClass: '',
        studentCities: '',
        studentDistrict: '',
        studentShift: '',
        email: '',
        studentBirthDate: '',
        password: '',
        info: '',
        selectedCourses: [],
        section: '' // Çoklu seçim için dizi
    });

    // Mevcut kurslar
    const allCourses = [
        "Gri",
        "Turkuaz",
        "Turuncu",
        "Siyah",
        "H.O T.S",
        "H.O İ.S",
        "H.T T.S",
        "H.T İ.S",
        "M.A Toplama",
        "M.A Çıkarma",
        "M.A Çarpma",
        "M.A Bölme",
        "Düşünce Eğitimi",
        "Dershane Eğitimi İlkokul",
        "Dershane Eğitimi Ortaokul",
        "Dershane Eğitimi Lise",
        "Matematik",
        "Fen Bilimleri",
        "Fizik",
        "Kimya",
        "Biyoloji",
        "Sosyal Bilgiler",
        "Tarih",
        "Türk Dili ve Edebiyatı",
        "Coğrafya",
        "İngilizce",
        // İhtiyaç duyduğunuz kadar kurs ekleyebilirsiniz
    ];

    // Öğrenci ve grup verilerini çekme
    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(`${API_URL}/api/users/all`);
                const data = await response.json();
                setStudents(data);
                setFilteredStudents(data); // filteredStudents state'ini başlangıçta tüm öğrencilerle dolduruyoruz
                data.sort((a, b) => a.fullName.localeCompare(b.fullName, 'tr'));
            } catch (error) {
                console.error('Öğrenciler alınırken bir hata oluştu:', error);
            }
        };
        const fetchGroups = async () => {
            try {
                const [responseGroups, responseCebir] = await Promise.all([
                    fetch(`${API_URL}/api/admin/course/all`),
                    fetch(API_CEBIR_COURSES_URL)
                ]);
        
                const dataGroups = await responseGroups.json();
                const dataCebir = await responseCebir.json();
        
                // Her gruba 'type' özelliği ekliyoruz
                const dataGroupsWithType = dataGroups.map(group => ({ ...group, type: 'regular' }));
                const dataCebirWithType = dataCebir.map(group => ({ ...group, type: 'cebir' }));
        
                // Grupları birleştiriyoruz
                const combinedGroups = [...dataGroupsWithType, ...dataCebirWithType];
        
                setGroups(combinedGroups);
            } catch (error) {
                console.error('Gruplar alınırken bir hata oluştu:', error);
            }
        };

        fetchStudents();
        fetchGroups();
    }, []);

    const handleStudentSelect = (student) => {
        setSelectedStudent(student);
        // Öğrenci verilerini forma yükleme
        setForm({
            fullName: student.fullName || '',
            familyFullName: student.familyFullName || '',
            family2FullName: student.family2FullName || '',
            familyPhone: student.familyPhone || '',
            studentPhone: student.studentPhone || '',
            studentClass: student.studentClass || '',
            studentCities: student.studentCities || '',
            studentDistrict: student.studentDistrict || '',
            studentShift: student.studentShift || '',
            email: student.email || '',
            studentBirthDate: student.studentBirthDate || '',
            password: student.password || '',
            info: student.info || '',
            selectedCourses: student.studentCourses || [],
            section: student.section || [],
        });
    };

    const handleCourseChange = (courseName) => {
        setForm(prev => {
            const selectedCourses = prev.selectedCourses.includes(courseName)
                ? prev.selectedCourses.filter(name => name !== courseName)
                : [...prev.selectedCourses, courseName];
            return { ...prev, selectedCourses };
        });
    };

    const handleChange = (e) => {
        const { name, value, options, type } = e.target;

        if (type === 'select-multiple') {
            const selectedValues = Array.from(options)
                .filter(option => option.selected)
                .map(option => option.value);

            setForm(prev => ({
                ...prev,
                [name]: selectedValues
            }));
        } else {
            setForm(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    // Grup kontenjanını güncelleyen fonksiyon
    const updateGroupStudents = async (groupId, updatedGroup, isCebir = false) => {
        try {
            const endpoint = isCebir
                ? `${API_CEBIR_COURSES_UPDATE}/${groupId}`
                : `${API_URL}/api/admin/course/update/${groupId}`;
    
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedGroup),
            });
    
            if (!response.ok) {
                throw new Error('Grup kontenjanı güncellenirken bir hata oluştu');
            }
        } catch (error) {
            console.error('Grup kontenjanı güncellenirken bir hata oluştu:', error);
        }
    };
    const handleUpdate = async (e) => {
        e.preventDefault();
    
        if (selectedStudent) {
            
            try {
                // Güncellenmiş öğrenci verilerini alıyoruz
                const submitData = {
                    ...selectedStudent,
                    ...form,
                    studentCourses: form.selectedCourses, // Seçilen kurslar
                    section: form.section, // Seçilen gruplar
                    info: form.info,
                };
                
                // Eski ve yeni grupları belirleme
                const oldGroups = selectedStudent.section || []; // Eski gruplar
                const newGroups = form.section || []; // Yeni seçilen gruplar
    
                const groupsAdded = newGroups.filter((group) => !oldGroups.includes(group)); // Yeni eklenen gruplar
                const groupsRemoved = oldGroups.filter((group) => !newGroups.includes(group)); // Kaldırılan gruplar
    
                let localGroups = [...groups];
    
                // Yeni eklenen grupların kontenjanını artırma
                for (const groupTitle of groupsRemoved) {
                    const selectedGroupIndex = localGroups.findIndex(group => group.title === groupTitle);
                    if (selectedGroupIndex !== -1) {
                        const selectedGroup = localGroups[selectedGroupIndex];
                        const currentStudents = Number(selectedGroup.students) || 0;
                        const updatedStudents = currentStudents + 1; // Öğrenci sayısını artırıyoruz (Doğru)
                
                        const updatedGroup = { ...selectedGroup, students: updatedStudents };
                        localGroups[selectedGroupIndex] = updatedGroup;
                
                        const isCebir = selectedGroup.type === 'cebir';
                
                        // Kontenjanı backend'de güncelle
                        await updateGroupStudents(selectedGroup.id, updatedGroup, isCebir);
                    }
                }
    
                // Çıkarılan grupların kontenjanını azaltma
                for (const groupTitle of groupsAdded) {
                    const selectedGroupIndex = localGroups.findIndex(group => group.title === groupTitle);
                    if (selectedGroupIndex !== -1) {
                        const selectedGroup = localGroups[selectedGroupIndex];
                        const currentStudents = Number(selectedGroup.students) || 0;
                        const updatedStudents = currentStudents - 1; // Öğrenci sayısını azaltıyoruz (Doğru)
                
                        const updatedGroup = { ...selectedGroup, students: updatedStudents };
                        localGroups[selectedGroupIndex] = updatedGroup;
                
                        const isCebir = selectedGroup.type === 'cebir';
                
                        // Kontenjanı backend'de güncelle
                        await updateGroupStudents(selectedGroup.id, updatedGroup, isCebir);
                    }
                }
                
    
                // localGroups state'ini güncelleme
                setGroups(localGroups);
    
                // Sections güncelleme isteği
                const sectionResponse = await fetch(`${API_URL}/api/users/${selectedStudent.id}/sections`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(submitData.section), // Yeni section listesini backend'e gönderiyoruz
                });
    
                if (!sectionResponse.ok) {
                    throw new Error("Sections güncellenirken hata oluştu.");
                }
    
                // Öğrenciyi güncelleme
                const response = await fetch(`${API_URL}/api/users/${selectedStudent.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(submitData), // Güncellenmiş veriler backend'e gönderiliyor
                });
    
                if (response.ok) {
                    const updatedStudent = await response.json(); // Backend'den güncellenmiş öğrenci verisini alıyoruz
                    // Öğrenci state'ini güncelleme
                    setStudents(students.map((student) => student.id === selectedStudent.id ? { ...updatedStudent } : student));
                    setSelectedStudent(updatedStudent); // selectedStudent'ı güncelliyoruz
                    setForm({
                        ...form,
                        section: updatedStudent.section || [],
                        selectedCourses: updatedStudent.studentCourses || [],
                        // Diğer alanları da güncelleyebilirsiniz
                    });
                    setNotificationMessage('Öğrenci başarıyla güncellendi.');
                    setNotificationType('success');
                   
                    console.log("Backend'e gönderilen veri: ", submitData);

                } else {
                    setNotificationMessage('Öğrenci güncellenirken bir hata oluştu.');
                    setNotificationType('error');
                }
    
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000);
    
            } catch (error) {
                console.error('Hata:', error);
                setNotificationMessage('Öğrenci güncellenirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000);
            }
        }
    };
    
        


    const handleSearch = (query) => {
        const lowercasedQuery = query.toLowerCase();
        const filtered = students.filter(student =>
            student.fullName.toLowerCase().startsWith(lowercasedQuery) // İsimlerle sorguyu eşleştir
        );
        setFilteredStudents(filtered);
    };

    return (
        <div className="student-editor">
            <h2>Öğrenci Düzenle</h2>
            <Search onSearch={handleSearch}></Search>
            <div className="editor-student-selector">
                <h3>Öğrenci Seç:</h3>
                {/* Notifikasyon Alanı */}
                {notificationMessage && (
                    <div className={`notification ${notificationType}`}>
                        {notificationMessage}
                    </div>
                )}

                {/* Öğrenci listesi veya "Öğrenci bulunamadı" mesajı */}
                {filteredStudents.length > 0 ? (
                    <ul className="student-list">
                        {filteredStudents.map((student) => (
                            <li key={student.id}>
                                <span>{student.fullName}</span>
                                <button onClick={() => handleStudentSelect(student)}>Düzenle</button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Öğrenci bulunamadı.</p>
                )}
            </div>

            {/* Düzenleme Formu */}
            {selectedStudent && (
                <div className="register-container">
                    <div className="editor-register-form-container">
                        <h1>Öğrenci Düzenle</h1>

                        <form className="register-form" onSubmit={handleUpdate}>
                            {notificationMessage && (
                                <div className={`notification ${notificationType}`}>
                                    {notificationMessage}
                                </div>
                            )}
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    value={form.fullName}
                                    onChange={handleChange}
                                    placeholder="Öğrenci Adı Soyadı"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="familyFullName"
                                    name="familyFullName"
                                    value={form.familyFullName}
                                    onChange={handleChange}
                                    placeholder="Veli Adı Soyadı"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="family2FullName"
                                    name="family2FullName"
                                    value={form.family2FullName}
                                    onChange={handleChange}
                                    placeholder="İkinci Veli Adı Soyadı (İsteğe Bağlı)"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="familyPhone"
                                    name="familyPhone"
                                    value={form.familyPhone}
                                    onChange={handleChange}
                                    placeholder="Veli Telefon Numarası"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="studentPhone"
                                    name="studentPhone"
                                    value={form.studentPhone}
                                    onChange={handleChange}
                                    placeholder="Öğrenci Telefon Numarası"
                                />
                            </div>
                            <div className="form-group">
                                <select
                                    id="studentClass"
                                    name="studentClass"
                                    value={form.studentClass}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Öğrenci sınıfı seçiniz</option>
                                    <option value="1.sınıf">1.sınıf</option>
                                    <option value="2.sınıf">2.sınıf</option>
                                    <option value="3.sınıf">3.sınıf</option>
                                    <option value="4.sınıf">4.sınıf</option>
                                    <option value="5.sınıf">5.sınıf</option>
                                    <option value="6.sınıf">6.sınıf</option>
                                    <option value="7.sınıf">7.sınıf</option>
                                    <option value="8.sınıf">8.sınıf</option>
                                    <option value="9.sınıf">9.sınıf</option>
                                    <option value="10.sınıf">10.sınıf</option>
                                    <option value="11.sınıf">11.sınıf</option>
                                    <option value="12.sınıf">12.sınıf</option>
                                    <option value="Mezun öğrenci">Mezun</option>
                                    <option value="Üniversite öğrencisi">Üniversite öğrencisi</option>
                                    <option value="Yetişkin">Yetişkin</option>
                                </select>
                            </div>
                            <section className="location-section">
                                <div className="form-group">
                                    <input
                                        id="city-select"
                                        name="studentCities"
                                        value={form.studentCities}
                                        onChange={handleChange}
                                        placeholder="Şehir Yazınız"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        id="district-select"
                                        name="studentDistrict"
                                        value={form.studentDistrict}
                                        onChange={handleChange}
                                        placeholder="İlçe Yazınız"
                                        required
                                    />
                                </div>
                            </section>
                            <div className="form-group">
                                <select
                                    id="studentShift"
                                    name="studentShift"
                                    value={form.studentShift}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Öğrenci okul saatleri</option>
                                    <option value="Sabah">Sabahçı</option>
                                    <option value="Öğlen">Öğlenci</option>
                                    <option value="Tam Gün">Tam Gün</option>
                                    <option value="Diğer">Diğer</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={form.email}
                                    onChange={handleChange}
                                    placeholder="E-posta"
                                 
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="date"
                                    id="studentBirthDate"
                                    name="studentBirthDate"
                                    value={form.studentBirthDate}
                                    onChange={handleChange}
                                    placeholder="Öğrenci Doğum Tarihi"
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    value={form.password}
                                    onChange={handleChange}
                                    placeholder="Şifre"
                                />
                            </div>
                            <div className="create-form-group">
                                <h3>Kurs Seçimi</h3>
                                {allCourses.map(course => (
                                    <div key={course}>
                                        <input
                                            type="checkbox"
                                            checked={form.selectedCourses.includes(course)}
                                            onChange={() => handleCourseChange(course)}
                                        />
                                        <label>{course}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="form-group">
                                <h3>Grup Seçimi</h3>
                                <select
                                    name="section"
                                    style={{ height: '350px' }}
                                    multiple
                                    value={form.section}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Grup seçiniz</option>
                                    {groups.map(group => (
                                        <option key={group.id} value={group.title}>
                                            {group.title}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    id="info"
                                    name="info"
                                    value={form.info}
                                    onChange={handleChange}
                                    placeholder="Açıklama"
                                />
                            </div>
                            <br />
                            <button type="submit" className="submit-button">Güncelle</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StudentEditor;
