import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/Home/Home'; // Home bileşenini import ediyoruz
import Login from './Components/Login/login';
import Register from './Components/Register/register';
import Dashboard from './Components/Dashboard/Dashboard';
import AdminLogin from './Components/AdminDashBoard/AdminLogin';
import AdminPanel from './Components/AdminDashBoard/AdminPanel';
import CoursePanel from './Components/AdminDashBoard/CoursePanel';
import StudentManager from './Components/StudentPanel/StudentManager';
import SpeedReading from './Components/SpeedReading/SpeedReading';
import TemelSeviye from './Components/SpeedReading/Beginner/Temelseviye';
import TemelSeviyeAction from './Components/SpeedReading/Beginner/TemelSeviyeAction';
import SoruEklePaneli from './Components/AdminDashBoard/MetinPanel/SoruEklePaneli';
import Question from './Components/SpeedReading/Beginner/Question';
import MetinEkle from './Components/AdminDashBoard/MetinPanel/MetinEkle';
import MetinPanel from './Components/AdminDashBoard/MetinPanel/MetinPanel';
import MetinDuzenle from './Components/AdminDashBoard/MetinPanel/MetinDuzenle';
import MetinSil from './Components/AdminDashBoard/MetinPanel/MetinSil';
import SoruDuzenlePaneli from './Components/AdminDashBoard/MetinPanel/SoruDuzenlePaneli';
import FirstPage from './Components/Education/FirstPage';
import Class from './Components/Education/Class';
import Lessons from './Components/Education/Lessons';
import Subject from './Components/Education/Subjects/Subjects';
import SubjectPanel from './Components/AdminDashBoard/SubjectPanel/SubjectPanel';


const App = () => {
    return (
        <Router>
            <div className="app-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login/>}/>
                    <Route path='/register' element={<Register/>}/>
                    <Route path='/dashboard' element={<Dashboard/>}/>
                    <Route path='/admin/afdb358b71e8b2f26262b3b206abb5071ed31405d7ee878399f21089769d5f46/login' element={<AdminLogin/>}/>
                    <Route path='/admin/069847f0e69ca29c8ae7ab9adde7e8eb287a5a95cb3e25b2b68de36e0949b0e4/panel' element={<AdminPanel/>}/>
                    <Route path='/admin/adde7e8eb287a5a95cb3e25b2b68de36e0949b0069847f0e69ca29c8ae7ab9e4/add/group' element={<CoursePanel/>}/>
                    <Route path='/admin/b3e25b2b68de36e0949b0069/add/student' element={<StudentManager/>}/>            
                    <Route path='/hızlı-okuma' element={<SpeedReading/>}/>     
                    <Route path='/hızlı-okuma-temel' element={<TemelSeviye/>}/>   
                    <Route path='/admin/87a5a95cb3e25/hızlı-okuma-metin-ekle' element={<MetinEkle/>}/>  
                    <Route path='/admin/87a5a95cb3e25/panel' element={<MetinPanel/>}/>  
                    <Route path="/temel-seviye-action/:metinId" element={<TemelSeviyeAction />} />
                    <Route path="/admin/soru-ekle/" element={<SoruEklePaneli/>} />
                    <Route path="/question/metin/:metinId" element={<Question/>} />
                    <Route path="/admin/metin-duzenle" element={<MetinDuzenle/>} />
                    <Route path="/admin/soru-duzenle" element={<SoruDuzenlePaneli/>} />
                    <Route path="/admin/metin-sil" element={<MetinSil/>} />
                    <Route path='/edu/firstpage' element={<FirstPage/>} />
                    <Route path='/edu/class-select' element={<Class/>} />
                    <Route path='/edu/lessons' element={<Lessons/>} />
                    <Route path='/subject/:grade/:subjectName' element={<Subject/>} />
                    <Route path='/admin/pdf/upload' element= {<SubjectPanel/>} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
