import React, { useState } from 'react';
import './ContactModal.css'; // CSS stil dosyasını ayırabiliriz.

const ContactModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <a href="#contact" className='cta-button' onClick={openModal}>İletişime Geç</a>

      {isOpen && (
        <div className="call-modal">
          <div className="call-modal-content">
           
            <h2>İletişim Bilgilerimiz</h2>
            <p>Numaramız: <strong>+90 531 823 45 01</strong></p>
            <p>Adres: <strong>Ata Cd. No:86 Onur Mahallesi, 35330 Balçova/İzmir</strong></p>
            
            <button className="call-button" onClick={() => window.location.href = 'tel:+90 531 823 45 01'}>
              İletişime Geç
            </button>
            <button className="close-button" onClick={closeModal}>Kapat</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactModal;
