import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles/FirstPage.css'; // FirstPage'in stil dosyası

const FirstPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // URL parametresinden sınıf bilgisini alma
  const queryParams = new URLSearchParams(location.search);
  const selectedClass = queryParams.get('class'); // Sınıf bilgisini alıyoruz

  const handleLessonClick = () =>{
    navigate('/edu/lessons')
  }

  return (
    <div className="firstPageContainer">
      <h1 className="pageTitle">Hoş Geldiniz</h1>
      <p className="firstpage__info">Seçilen Sınıf: {selectedClass}</p>
      <div className="buttonContainer">
      <button onClick={handleLessonClick} className="button button--test">Konu Anlatımı</button>
      
      <button className="button button--confirm">Testler </button>
      </div>
    </div>
  );
};

export default FirstPage;
