import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';
import '../styles/Subject.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

const subjects = {
  turkce: {
    title: "turkce",
    pdf: `${process.env.PUBLIC_URL}/pdfs/pdf-test.pdf`,
    units: ["1. Ünite", "2. Ünite", "3. Ünite"],
  },
  matematik: {
    title: "Matematik",
    pdf: `${process.env.PUBLIC_URL}/pdfs/matematik.pdf`,
    units: ["1. Ünite", "2. Ünite", "3. Ünite"],
  },
  // Diğer dersler...
};

const Subject = () => {
  const { subjectName } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(true);

  const subject = subjects[subjectName];

  if (!subject) {
    return <div>Ders bulunamadı.</div>;
  }

  // PDF yükleme ve sayfa kontrol fonksiyonları
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const onPageRenderSuccess = () => {
    setLoading(false);
  };

  const handleNext = () => {
    if (currentPage < numPages) {
      setLoading(true);
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setLoading(true);
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="subject__container">
      <div className="subject__sidebar">
        <h2>{subject.title}</h2>
        <ul>
          {subject.units.map((unit, index) => (
            <li key={index}>
              <button className="subject__unit-button">{unit}</button>
            </li>
          ))}
        </ul>
      </div>

      <div className="subject__content">
        <div className="subject__pdf">
          {loading && (
            <div className="loading-overlay">
              <p>Yükleniyor...</p>
            </div>
          )}

          <Document
            file={subject.pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<p>Yükleniyor...</p>}
            options={{
              disableAutoFetch: false,
              disableStream: false,
            }}
          >
            <Page
              pageNumber={currentPage}
              onRenderSuccess={onPageRenderSuccess}
            />
          </Document>
        </div>

        <div className="subject__controls">
          <button onClick={handlePrevious} className="subject__control-button" disabled={loading || currentPage === 1}>
            GERİ
          </button>
          <span>Sayfa {currentPage} / {numPages}</span>
          <button onClick={handleNext} className="subject__control-button" disabled={loading || currentPage === numPages}>
            İLERİ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subject;
