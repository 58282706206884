import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import './TemelSeviyeAction.css';

const TemelSeviyeAction = () => {
  const { metinId } = useParams();  // URL'den metinId'yi alıyoruz
  const location = useLocation();
  const navigate = useNavigate();

  const { kelimeSayisi, sure } = location.state;  // URL ile birlikte gelen state
  const [detail, setDetail] = useState('');  // Metin içeriği
  const [displayedText, setDisplayedText] = useState('');  // Şu an ekranda gösterilen metin
  const [remainingWords, setRemainingWords] = useState([]);  // Kalan kelimeleri tutacak state
  const [isCompleted, setIsCompleted] = useState(false);  // Metin gösterimi tamamlandı mı?

  useEffect(() => {
    // Metni API'den çekiyoruz
    fetch(`https://ankahizliokuma.com/api/texts/metin/${metinId}`)
      .then((response) => response.json())
      .then((data) => {
        const wordsArray = data.detail.split(' ');  // Metni boşluklara göre kelimelere böl
        setRemainingWords(wordsArray);  // Kalan kelimeleri ilk olarak tüm kelimelerle başlatıyoruz
      })
      .catch((error) => console.error('Hata oluştu:', error));
  }, [metinId]);

  useEffect(() => {
    if (remainingWords.length > 0 && !isCompleted) {
      let intervalId;

      const showNextWords = () => {
        if (remainingWords.length <= kelimeSayisi) {
          // Eğer kalan kelime sayısı kelimeSayisi'ndan azsa, tüm kalan kelimeleri göster
          setDisplayedText(remainingWords.join(' '));
          setRemainingWords([]);  // Kalan kelimeleri boşaltıyoruz çünkü tüm kelimeler gösterildi
          setIsCompleted(true);  // Metnin sonuna ulaşıldı
        } else {
          const nextWords = remainingWords.slice(0, kelimeSayisi);  // Sıradaki kelimeleri al
          setDisplayedText(nextWords.join(' '));  // Yeni kelime grubunu göster ve öncekileri sil
          setRemainingWords((prevWords) => prevWords.slice(kelimeSayisi));  // Kalan kelimeleri güncelle
        }
      };

      // Her kelime bloğunu belirlenen süre sonunda sırayla göster
      intervalId = setInterval(showNextWords, sure * 1000);  // sure saniye cinsinden olduğu için 1000 ile çarpıyoruz

      // Bileşen unmount olduğunda interval'i temizle
      return () => clearInterval(intervalId);
    }
  }, [remainingWords, kelimeSayisi, sure, isCompleted]);

  // Eğer metin gösterimi tamamlandıysa navigate işlemi burada yapılır
  useEffect(() => {
    if (isCompleted) {
      setTimeout(() => {
        navigate(`/question/metin/${metinId}`);  // Soru sayfasına yönlendir
      }, 2000);  // Metin bittikten 2 saniye sonra soruya yönlendir
    }
  }, [isCompleted, navigate, metinId]);

  return (
    <div className="temel-seviye-action-container">
      {displayedText && <p className="big-text">{displayedText.trim()}</p>}  {/* trim() gereksiz boşlukları temizler */}
    </div>
  );
};

export default TemelSeviyeAction;


