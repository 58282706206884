import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Class.css'; // BEM yapısına uygun CSS dosyası

const Class = () => {
  const navigate = useNavigate();
  const [selectedClass, setSelectedClass] = useState('');

  const classOptions = [
    '1. Sınıf', '2. Sınıf', '3. Sınıf', '4. Sınıf', '5. Sınıf', '6. Sınıf',
    '7. Sınıf', '8. Sınıf', '9. Sınıf', '10. Sınıf', '11. Sınıf', '12. Sınıf', 'Mezunlar'
  ];

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedClass) {
      navigate(`/edu/firstpage?class=${selectedClass}`);
    } else {
      alert('Lütfen bir sınıf seçin!');
    }
  };

  return (
    <div className="Class__container">
      <h1 className="Class__title">Sınıf Seçimi</h1>
      <select className="Class__select" value={selectedClass} onChange={handleClassChange}>
        <option value="">Sınıfınızı Seçin</option>
        {classOptions.map((classOption, index) => (
          <option key={index} value={classOption}>{classOption}</option>
        ))}
      </select>
      <button className="Class__button Class__button--confirm" onClick={handleSubmit}>Onayla</button>
    </div>
  );
};

export default Class;
