import React, { useState, useEffect } from 'react';
import './StudentList.css'; // CSS dosyasını import edin
import Search from './Search'; // Yeni arama bileşenini import edin

const API_URL = "https://ankahizliokuma.com/api/users/all"
// Badge Mapping - Rozetlerin kurslarla eşleştirilmesi
const badgeMapping = {
    'Gri': '/imgs/Gri.png',
    'Turkuaz': '/imgs/Turkuaz.png',
    'Turuncu': '/imgs/Turuncu.png',
    'Siyah': '/imgs/Siyah.png',
    'H.O T.S': '/imgs/H.O.T.S.png',
    'H.O İ.S': '/imgs/H.O.İ.S.png',
    'H.T T.S': '/imgs/H.T.T.S.png',
    "H.T İ.S": '/imgs/H.T.İ.S.png',
    'Düşünce Eğitimi': '/imgs/D.E.png',
    "M.A Toplama": '/imgs/M.AToplama.png',
    "M.A Çıkarma": '/imgs/M.AÇıkarma.png',
    "M.A Çarpma": '/imgs/M.AÇarpma.png',
    "M.A Bölme": '/imgs/M.ABölme.png',
    "Dershane Eğitimi": '/imgs/Dershane.png', // Tek rozet için Dershane Eğitimi
};


// Rozet sıralaması - Rozetlerin sabit sırası
const badgeOrder = [
    'Gri',
    'Turkuaz',
    'Turuncu',
    'Siyah',
    "H.O T.S",
    "H.O İ.S",
    "H.T T.S",
    "H.T İ.S",
    "Düşünce Eğitimi",
    "M.A Toplama",
    "M.A Çıkarma",
    "M.A Çarpma",
    "M.A Bölme",
    "Dershane Eğitimi" // Artık tek bir dershane eğitimi rozetimiz var
];

const colorBadges = ['Gri', 'Turkuaz', 'Turuncu', 'Siyah'];
// Dershane Eğitimleri

const dershaneCourses = [
    "Dershane Eğitimi İlkokul",
    "Dershane Eğitimi Ortaokul",
    "Dershane Eğitimi Lise"
];

const StudentList = ({ title, onStudentSelect }) => {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [selectedStudent, setSelectedStudent] = useState(null);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const response = await fetch(API_URL);
                if (!response.ok) {
                    throw new Error('Veri alınamadı.');
                }
                const data = await response.json();
                data.sort((a, b) => a.fullName.localeCompare(b.fullName, 'tr'));

                // Benzersiz öğrencileri filtrele
                const uniqueStudents = Array.from(new Map(data.map(student =>
                    [student.fullName + student.familyFullName + student.studentBirthDate, student]
                )).values());

                setStudents(uniqueStudents);
                setFilteredStudents(uniqueStudents);
            } catch (error) {
                console.error('Öğrenciler alınırken bir hata oluştu:', error);
                setNotificationMessage('Öğrenciler yüklenirken bir hata oluştu.');
                setNotificationType('error');
                setTimeout(() => {
                    setNotificationMessage('');
                }, 3000);
            }
        };

        fetchStudents();
    }, []);

    const handleSearch = (query) => {
        const lowercasedQuery = query.toLowerCase();
        
        const filtered = students.filter(student => {
            const [firstName, lastName] = student.fullName.toLowerCase().split(' '); // İsmi ve soyismi ayır
            const familyFullName = student.familyFullName.toLowerCase(); // familyFullName'i küçük harfe çevir
    
            // İsim, soyisim ya da familyFullName ile eşleştir
            return firstName.startsWith(lowercasedQuery) || 
                   lastName?.startsWith(lowercasedQuery) || 
                   familyFullName.startsWith(lowercasedQuery);
        });
    
        setFilteredStudents(filtered);
    };
    

    const handleStudentClick = (student) => {
        setSelectedStudent(selectedStudent === student ? null : student);
    };

    // Rozetleri sabit sıraya göre döndürme
    const getOrderedBadges = (studentCourses) => {
        const badges = [];

        // Handle Color Badges Together
        const studentColorBadges = colorBadges.filter(color => studentCourses.includes(color));

        if (studentColorBadges.length > 0) {
            // Create a container for color badges
            badges.push(
                <div key="color-badges" className="color-badge-container">
                    {studentColorBadges.map(color => (
                        <img
                            key={color}
                            src={badgeMapping[color]}
                            alt={`${color} Rozeti`}
                            className="badge-icon"
                        />
                    ))}
                </div>
            );
        } else {
            // Add a placeholder if the student has no color badges
            badges.push(
                <div key="color-badges-placeholder" className="badge-placeholder"></div>
            );
        }

        // Now process the rest of the badges
        badgeOrder.forEach((badgeKey) => {
            if (colorBadges.includes(badgeKey)) {
                // Skip the color badges since we've already handled them
                return;
            }

            const badgeSrc = badgeMapping[badgeKey];

            if (badgeKey === 'Dershane Eğitimi') {
                // Handle Dershane Eğitimi badge
                const hasDershaneCourse = studentCourses.some(course => dershaneCourses.includes(course));
                if (hasDershaneCourse) {
                    badges.push(
                        <img
                            key={badgeKey}
                            src={badgeSrc}
                            alt={`${badgeKey} Rozeti`}
                            className="badge-icon"
                        />
                    );
                } else {
                    badges.push(
                        <div
                            key={badgeKey}
                            className="badge-placeholder"
                        ></div>
                    );
                }
            } else {
                if (studentCourses.includes(badgeKey)) {
                    badges.push(
                        <img
                            key={badgeKey}
                            src={badgeSrc}
                            alt={`${badgeKey} Rozeti`}
                            className="badge-icon"
                        />
                    );
                } else {
                    badges.push(
                        <div
                            key={badgeKey}
                            className="badge-placeholder"
                        ></div>
                    );
                }
            }
        });

        return badges;
    };

    return (
        <div className="student-list-container">
            <h2>{title}</h2>
            <h3>Kayıtlı Öğrenci Sayısı : {filteredStudents.length}</h3>
            <Search onSearch={handleSearch} /> {/* Arama bileşenini ekleyin */}
            {/* Notifikasyon Alanı */}
            {notificationMessage && (
                <div className={`notification ${notificationType}`}>
                    {notificationMessage}
                </div>
            )}
            <div className="student-list-wrapper">
                {filteredStudents.length > 0 ? (
                    <ul className="student-list">
                        {filteredStudents.map((student) => (
                            <li
                                key={student.id}
                                className="student-list-item"
                                onClick={() => handleStudentClick(student)}
                            >
                                {/* Öğrenci Ana Bilgileri */}
                                <div className="student-main-info" style={{ display: 'flex', alignItems: 'center' }}>
                                    {selectedStudent !== student ? (
                                        <>
                                            <span
                                                className="student-name"
                                                style={{
                                                    flexGrow: 1,
                                                    color: student.banned ? 'red' : 'black', // banned durumu true ise kırmızı, değilse siyah
                                                }}
                                            >
                                                {student.fullName}
                                            </span>
                                            <div className="badge-container">
                                                {getOrderedBadges(student.studentCourses)}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <span
                                                className="student-name"
                                                style={{ flexGrow: 1, visibility: 'hidden' }}
                                            ></span>
                                            <div className="badge-container" style={{ visibility: 'hidden' }}>
                                                {getOrderedBadges(student.studentCourses).map((badge, index) => (
                                                    <div key={index} className="badge-placeholder"></div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </div>
                                {/* Öğrenci Detayları */}
                                {selectedStudent === student && (
                                    <div className="student-detail-container">
                                        <div className="student-detail">
                                            <p><strong>Öğrenci Adı Soyadı:</strong> {student.fullName}</p>
                                            <p><strong>Veli Adı Soyadı:</strong> {student.familyFullName}</p>
                                            <p><strong>İkinci Veli Adı Soyadı:</strong> {student.family2FullName}</p>
                                            <p><strong>Aile Telefonu:</strong> {student.familyPhone}</p>
                                            <p><strong>Öğrenci Telefonu:</strong> {student.studentPhone}</p>
                                            <p><strong>Sınıf:</strong> {student.studentClass}</p>
                                            <p><strong>Şehir:</strong> {student.studentCities}</p>
                                            <p><strong>İlçe:</strong> {student.studentDistrict}</p>
                                            <p><strong>Öğrenci Saatleri:</strong> {student.studentShift}</p>
                                            <p><strong>E-posta:</strong> {student.email}</p>
                                            <p><strong>Doğum Tarihi:</strong> {new Date(student.studentBirthDate).toLocaleDateString()}</p>
                                            <p><strong>Açıklama:</strong> {student.info}</p>
                                            <p><strong>Öğrencinin Aldığı Kurslar:</strong> {student.studentCourses.join(', ')}</p>
                                            <p><strong>Öğrencinin Kayıtlı Olduğu Gruplar:</strong> {student.section.join(', ')}</p>
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Öğrenci bulunamadı.</p>
                )}
            </div>
        </div>
    );
};

export default StudentList;
