import React, { useState } from 'react';
import StudentCreate from './StudentCreate'; // Kurs ekleme bileşeni
import "./StudentManager.css"
import StudentEditor from './StudentEditor';
import StudentDeleter from './StudentDeleter';
import StudentList from './StudentList';
import { useNavigate } from 'react-router-dom';


const StudentManager = () => {
    const [activePage, setActivePage] = useState('default'); 

    const navigate = useNavigate();
    const handleclickevent = () =>{
        
       navigate("/admin/069847f0e69ca29c8ae7ab9adde7e8eb287a5a95cb3e25b2b68de36e0949b0e4/panel");
    }
    const renderContent = () => {
        switch (activePage) {
            case 'ekle':
             return <StudentCreate />; // Öğrenci ekleme bileşenini render et
            case 'duzenle':
             return <StudentEditor />; // Öğrenci düzenleme bileşenini render et
            case 'sil':
             return  <StudentDeleter /> // Öğrenci silme bileşenini render et 
             

            default:
            return <StudentList />; // Varsayılan olarak Öğrenci Listesi
        }
    };

    return (
        <div className="student-manager-panel">
            <div className="sidebar">
                <button onClick={(handleclickevent)}>Anasayfa</button>
                <button onClick={() => setActivePage('ekle')}>Öğrenci Ekle</button>
                <button onClick={() => setActivePage('duzenle')}>Öğrenci Kaydı Düzenle</button>
                <button onClick={() => setActivePage('sil')}> Öğrenci Kaydı Sil</button>
                <button onClick={() => setActivePage('listele')}>Bütün Öğrenci Kayıtları</button>
               
            </div>
            <div className="content">
                {renderContent()} {/* Seçili sayfaya göre içerik render ediliyor */}
            </div>
        </div>
    );
};

export default StudentManager;
