import React, { useState, useEffect } from 'react';
import './AdminPanel.css';
import { useNavigate } from 'react-router-dom';

const AdminPanel = () => {
    const [admin, setAdmin] = useState(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const handleCourse = () => {
        navigate('/admin/adde7e8eb287a5a95cb3e25b2b68de36e0949b0069847f0e69ca29c8ae7ab9e4/add/group');
    };

    const handleStudent = () => {
        navigate('/admin/b3e25b2b68de36e0949b0069/add/student');
    };                          

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/check-admin-session`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (response.status === 401) {
                    navigate('/admin/afdb358b71e8b2f26262b3b206abb5071ed31405d7ee878399f21089769d5f46/login');
                } else {
                    const adminResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/1f07b55c878d82ce996279823d5f873f2aecd3b782def7ab3cfd751d2f427122`, {
                        method: 'GET',
                        credentials: 'include',
                    });

                    if (adminResponse.ok) {
                        const data = await adminResponse.json();
                        console.log('Admin data:', data); // Burada admin verisini kontrol edin
                        setAdmin(data); // Admin state'ini güncelleyin
                    } else {
                        console.error('Failed to fetch admin data');
                    }
                }
            } catch (error) {
                console.error('An error occurred:', error);
                navigate('/afdb358b71e8b2f26262b3b206abb5071ed31405d7ee878399f21089769d5f46/login');
            } finally {
                setIsLoading(false);
            }
        };

        checkSession();
    }, [navigate]);

    return (
        <div className="admin-home-container">
            <header className="admin-home-header">
                <img className='admin-header-image' src='/imgs/admin-header-img.png' alt='Anka-icon' />
                <button className='admin-menu-toggle' onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    <span className={isMenuOpen ? 'icon-close' : 'icon-hamburger'}>
                        {isMenuOpen ? '✕' : '☰'}
                    </span>
                </button>
                <nav className={`admin-navbar ${isMenuOpen ? 'open' : ''}`}>
                    <div className='admin-header-info'>
                        <h2 style={{ fontWeight: "initial" }}>Mentalite Eğitim Yönetim Paneli</h2>
                    </div>
                    <ul className='admin-nav-list'>
                        <li><a href='/admin/b3e25b2b68de36e0949b0069/add/student'>Öğrenci İşlemleri</a></li>
                        <li><a href='/admin/adde7e8eb287a5a95cb3e25b2b68de36e0949b0069847f0e69ca29c8ae7ab9e4/add/group'>Grup Oluştur</a></li>
                        <li><a href='/admin/87a5a95cb3e25/panel'>Anka Hızlı Okuma</a></li>
                        <li className='dropdown'>
                            <button className='dropbtn'>
                                {admin ? admin.adminName : 'Loading...'}
                            </button>
                            <div className='dropdown-content'>
                                <a href='#profile'>Profil</a>
                                <a href='#settings'>Ayarlar</a>
                                <a href='/' >Çıkış Yap</a>
                            </div>
                        </li>
                    </ul>
                </nav>
            </header>
            <div className="admin-boxes-container">
                <div className="admin-box">
                    <img style={{ cursor: "pointer" }} onClick={handleCourse} src="/imgs/admin-course-add.png" alt="" />
                    <h2><a style={{ color: 'white', textDecoration: 'none' }} href='/admin/adde7e8eb287a5a95cb3e25b2b68de36e0949b0069847f0e69ca29c8ae7ab9e4/add/group'>Kurs İşlemleri</a></h2>
                </div>
                <div className="admin-box">
                    <img style={{ cursor: "pointer" }} onClick={handleStudent} src="/imgs/admin-student.png" alt="" />
                    <h2><a style={{ color: 'white', textDecoration: 'none' }} href='/admin/b3e25b2b68de36e0949b0069/add/student'>Öğrenci İşlemleri</a></h2>
                </div>
                <div className="admin-box">
                    <img src="/imgs/admin-settings.png" alt="" />
                    <h2>Ayarlar</h2>
                </div>
            </div>
        </div>
    );
};

export default AdminPanel;
