import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './sorueklepaneli.css';

const SoruEklePaneli = () => {
  const navigate = useNavigate();

  // Metinleri saklamak için state
  const [metinler, setMetinler] = useState([]);
  const [selectedMetinId, setSelectedMetinId] = useState(''); // Seçilen metnin ID'si

  const [questionType, setQuestionType] = useState('MULTIPLE_CHOICE'); // Varsayılan soru türü

  const [multipleChoiceData, setMultipleChoiceData] = useState({
    question: '',
    options: ['', '', '', ''],
    correctOption: 0 // Doğru şık indeksi
  });

  const [openEndedData, setOpenEndedData] = useState({
    question: ''
  });


  const setCorrectOption = (questionId, optionId) => {
    fetch(`https://ankahizliokuma.com/api/option/setCorrectOption/${questionId}/${optionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Doğru şık ayarlanırken hata oluştu.');
        }
      })
      .then((data) => {
        console.log('Doğru şık ayarlandı:', data);
      })
      .catch((error) => {
        console.error('Hata:', error);
      });
  };


  // API'den tüm metinleri çekmek için useEffect kullanımı
  useEffect(() => {
    fetch('https://ankahizliokuma.com/api/texts/metin/all')
      .then(response => response.json())
      .then(data => setMetinler(data))
      .catch(error => console.error('Error fetching metinler:', error));
  }, []);

  // Soru türünü değiştiren fonksiyon
  const handleQuestionTypeChange = (e) => {
    setQuestionType(e.target.value);
  };

  // Çoktan seçmeli soru için seçenek değişikliklerini yönetir
  const handleMultipleChoiceChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('option')) {
      const index = parseInt(name.split('_')[1], 10);
      const newOptions = [...multipleChoiceData.options];
      newOptions[index] = value;
      setMultipleChoiceData({ ...multipleChoiceData, options: newOptions });
    } else if (name === 'correctOption') {
      setMultipleChoiceData({ ...multipleChoiceData, correctOption: parseInt(value, 10) });
    } else {
      setMultipleChoiceData({ ...multipleChoiceData, [name]: value });
    }
  };

  // Açık uçlu soru metnini yönetir
  const handleOpenEndedChange = (e) => {
    setOpenEndedData({ ...openEndedData, question: e.target.value });
  };

  // Formu submit ettiğimizde soruyu API'ye gönderir
  const handleSubmit = () => {
    if (!selectedMetinId) {
      toast.warning('Lütfen Bir Metin Seçin!', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
  
    if (questionType === 'MULTIPLE_CHOICE') {
      const payload = {
        question: multipleChoiceData.question,
        type: questionType,
        metinId: selectedMetinId, // Kullanıcının seçtiği metin ID'sini kullanıyoruz
        options: multipleChoiceData.options.map((option, index) => ({
          optionText: option,
          isCorrect: index === multipleChoiceData.correctOption,
        })),
      };
  
      // Çoktan seçmeli soruyu ekleme
      fetch('https://ankahizliokuma.com/api/question/add/multiple-choice', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          // Doğru şıkkı backend'e gönderiyoruz
          const correctOptionId = data.options[multipleChoiceData.correctOption].id;
          setCorrectOption(data.id, correctOptionId); // Doğru şıkkı belirleme
  
          toast.success('Çoktan Seçmeli Soru başarıyla eklendi!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
  
          // Formu temizle
          setMultipleChoiceData({
            question: '',
            options: ['', '', '', ''],
            correctOption: 0,
          });
          setSelectedMetinId('');

        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error('Bir hata oluştu. Lütfen tekrar deneyin.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else if (questionType === 'OPEN_ENDED') {
      const payload = {
        question: openEndedData.question,
        type: questionType,
        metinId: selectedMetinId, // Kullanıcının seçtiği metin ID'sini kullanıyoruz
      };
  
      // Açık uçlu soruyu ekleme
      fetch('https://ankahizliokuma.com/api/question/add/open-ended', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then(() => {
          toast.success('Açık uçlu soru başarıyla eklendi!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
  
          // Formu temizle
          setOpenEndedData({
            question: '',
          });
          setSelectedMetinId('');
          
        })
        .catch((error) => {
          console.error('Error:', error);
          toast.error('Bir hata oluştu. Lütfen tekrar deneyin.', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    }
  };

  return (
    <div className="soru-ekle-paneli">
      {/* Bildirimlerin görüneceği yer */}
      <ToastContainer />

      <h1>Soru Ekle</h1>

      {/* Metin seçimi */}
      <label htmlFor="metinSelect">Metin Seçiniz:</label>
      <select
        id="metinSelect"
        value={selectedMetinId}
        onChange={(e) => setSelectedMetinId(e.target.value)}
        required
      >
        <option value="">Bir metin seçin</option>
        {metinler.map((metin) => (
          <option key={metin.id} value={metin.id}>
            {metin.title}
          </option>
        ))}
      </select>

      <label htmlFor="questionType">Soru Türü:</label>
      <select id="questionType" value={questionType} onChange={handleQuestionTypeChange}>
        <option value="MULTIPLE_CHOICE">Çoktan Seçmeli</option>
        <option value="OPEN_ENDED">Açık Uçlu</option>
      </select>

      {questionType === 'MULTIPLE_CHOICE' ? (
        <div className="multiple-choice-section">
          <label htmlFor="question">Soru:</label>
          <input
            type="text"
            id="question"
            name="question"
            value={multipleChoiceData.question}
            onChange={handleMultipleChoiceChange}
            placeholder="Soru metnini girin"
            required
          />

          {['A', 'B', 'C', 'D'].map((label, index) => (
            <div key={index} className="option-container">
              <input
                type="text"
                id={`option_${index}`}
                name={`option_${index}`}
                value={multipleChoiceData.options[index]}
                onChange={handleMultipleChoiceChange}
                placeholder={`Şık ${label}`}
                required
              />
            </div>
          ))}

          <label htmlFor="correctOption">Doğru Şık:</label>
          <select
            id="correctOption"
            name="correctOption"
            value={multipleChoiceData.correctOption}
            onChange={handleMultipleChoiceChange}
          >
            {['A', 'B', 'C', 'D'].map((label, index) => (
              <option key={index} value={index}>
                Şık {label}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="open-ended-section">
          <label htmlFor="openEndedQuestion">Açık Uçlu Soru:</label>
          <input
            type="text"
            id="openEndedQuestion"
            name="question"
            value={openEndedData.question}
            onChange={handleOpenEndedChange}
            placeholder="Açık uçlu soru metnini girin"
            required
          />
        </div>
      )}

      <button className="add-question-button" onClick={handleSubmit}>
        {questionType === 'MULTIPLE_CHOICE' ? 'Çoktan Seçmeli Soruyu Ekle' : 'Açık Uçlu Soruyu Ekle'}
      </button>

      <button className="question-back-button" onClick={() => window.history.back()}>
        Geri Dön
      </button>
    </div>
  );
};

export default SoruEklePaneli;
