import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style/soruduzenlepaneli.css';

const SoruDuzenlePaneli = () => {
    const [metinler, setMetinler] = useState([]);
    const [selectedMetinId, setSelectedMetinId] = useState('');
    const [sorular, setSorular] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState('');
    const [questionType, setQuestionType] = useState('');
    const [multipleChoiceData, setMultipleChoiceData] = useState({
        question: '',
        options: [],
        correctOption: 0,
    });
    const [openEndedData, setOpenEndedData] = useState({
        question: '',
    });

    // Fetch all metinler when component mounts
    useEffect(() => {
        fetch('https://ankahizliokuma.com/api/texts/metin/all')
            .then((response) => response.json())
            .then((data) => setMetinler(data))
            .catch((error) => console.error('Error fetching metinler:', error));
    }, []);

    // Fetch sorular when selectedMetinId changes
    useEffect(() => {
        if (selectedMetinId) {
            fetchSorular(selectedMetinId);
        }
    }, [selectedMetinId]);

    // Fetch sorular function
    const fetchSorular = (metinId) => {
        fetch(`https://ankahizliokuma.com/api/question/metin/${metinId}`)
            .then((response) => response.json())
            .then((data) => {
                console.log('Fetched sorular data:', data);
                setSorular(data);
            })
            .catch((error) => console.error('Error fetching sorular:', error));
    };

    // Fetch question details function
    const fetchQuestionDetails = (questionId) => {
        const qId = Number(questionId);
        const selectedQuestion = sorular.find((soru) => soru.id === qId);

        if (selectedQuestion) {
            if (selectedQuestion.type === 'MULTIPLE_CHOICE') {
                fetch(`https://ankahizliokuma.com/api/option/question/${questionId}`)
                    .then((response) => response.json())
                    .then((optionsData) => {
                        console.log('Fetched options data:', optionsData);

                        const correctOptionIndex = optionsData.findIndex((option) => option.correct === true || option.correct === 'true');

                        setQuestionType('MULTIPLE_CHOICE');
                        setMultipleChoiceData({
                            question: selectedQuestion.question,
                            options: optionsData.map((option) => ({
                                id: option.id,
                                optionText: option.optionText,
                                correct: option.correct === true || option.correct === 'true',
                            })),
                            correctOption: correctOptionIndex >= 0 ? correctOptionIndex : 0,
                        });
                    })
                    .catch((error) => console.error('Error fetching options:', error));
            } else if (selectedQuestion.type === 'OPEN_ENDED') {
                setQuestionType('OPEN_ENDED');
                setOpenEndedData({
                    question: selectedQuestion.question,
                });
            }
        } else {
            console.error('Selected question not found in sorular array');
        }
    };

    // Handle metin selection
    const handleMetinSelect = (e) => {
        const metinId = e.target.value;
        setSelectedMetinId(metinId);
        setSelectedQuestionId(''); // Reset selected question
        setQuestionType(''); // Reset question type
        setMultipleChoiceData({
            question: '',
            options: [],
            correctOption: 0,
        });
        setOpenEndedData({
            question: '',
        });
    };

    // Handle question selection
    const handleQuestionSelect = (e) => {
        const questionId = e.target.value;
        setSelectedQuestionId(questionId);
        fetchQuestionDetails(questionId);
    };

    // Handle multiple choice changes
    const handleMultipleChoiceChange = (e) => {
        const { name, value } = e.target;

        if (name.startsWith('option')) {
            const index = parseInt(name.split('_')[1], 10);
            const newOptions = [...multipleChoiceData.options];
            newOptions[index] = {
                ...newOptions[index],
                optionText: value,
            };
            setMultipleChoiceData({ ...multipleChoiceData, options: newOptions });
        } else if (name === 'correctOption') {
            setMultipleChoiceData({ ...multipleChoiceData, correctOption: parseInt(value, 10) });
        } else if (name === 'question') {
            setMultipleChoiceData({ ...multipleChoiceData, question: value });
        }
    };

    // Handle open ended question changes
    const handleOpenEndedChange = (e) => {
        const { name, value } = e.target;
        setOpenEndedData({ ...openEndedData, [name]: value });
    };

    // Handle option text change
    const handleOptionTextChange = (optionId, newText) => {
        const updatedOptions = multipleChoiceData.options.map((option) => {
            if (option.id === optionId) {
                return { ...option, optionText: newText };
            }
            return option;
        });
        setMultipleChoiceData({ ...multipleChoiceData, options: updatedOptions });
    };

    // Handle option update
    const handleUpdateOption = (optionId, optionText, correct) => {
        const payload = {
            optionText: optionText,
            correct: correct,
        };

        fetch(`https://ankahizliokuma.com/api/option/update/${optionId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        throw new Error(errorData.message || 'Güncelleme başarısız oldu');
                    });
                }
                return response.json();
            })
            .then(() => {
                toast.success('Seçenek başarıyla güncellendi!', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                // Güncellenmiş seçenekleri yeniden çek
                fetchQuestionDetails(selectedQuestionId);
            })
            .catch((error) => {
                console.error('Error updating the option:', error);
                toast.error(`Bir hata oluştu: ${error.message}`, {
                    position: 'top-right',
                    autoClose: 5000,
                });
            });
    };

    // Handle submit (update question)
    const handleSubmit = () => {
        const payload =
            questionType === 'MULTIPLE_CHOICE'
                ? {
                      metinId: selectedMetinId,
                      question: multipleChoiceData.question,
                      type: 'MULTIPLE_CHOICE',
                      options: multipleChoiceData.options.map((option, index) => ({
                          id: option.id,
                          optionText: option.optionText,
                          correct: index === multipleChoiceData.correctOption,
                      })),
                  }
                : {
                      metinId: selectedMetinId,
                      question: openEndedData.question,
                      type: 'OPEN_ENDED',
                  };

        const endpoint =
            questionType === 'MULTIPLE_CHOICE'
                ? `https://ankahizliokuma.com/api/question/update/multiple-choice/${selectedQuestionId}`
                : `https://ankahizliokuma.com/api/question/update/open-ended/${selectedQuestionId}`;

        fetch(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        throw new Error(errorData.message || 'Güncelleme başarısız oldu');
                    });
                }
                return response.json();
            })
            .then(() => {
                toast.success('Soru başarıyla güncellendi!', {
                    position: 'top-right',
                    autoClose: 3000,
                });
                // Güncellenmiş soruları yeniden çek
                if (selectedMetinId) {
                    fetchSorular(selectedMetinId);
                }
                setSelectedQuestionId('');
                setQuestionType('');
                setMultipleChoiceData({
                    question: '',
                    options: [],
                    correctOption: 0,
                });
                setOpenEndedData({
                    question: '',
                });
            })
            .catch((error) => {
                console.error('Error updating the question:', error);
                toast.error(`Bir hata oluştu: ${error.message}`, {
                    position: 'top-right',
                    autoClose: 5000,
                });
            });
    };

    // Handle delete question
    const handleDeleteQuestion = (questionId) => {
        if (!questionId) return;

        // Kullanıcıdan onay almak için
        if (!window.confirm('Bu soruyu silmek istediğinizden emin misiniz?')) {
            return;
        }

        fetch(`https://ankahizliokuma.com/api/question/delete/${questionId}`, {
            method: 'DELETE',
        })
            .then((response) => {
                if (response.status === 204) {
                    toast.success('Soru başarıyla silindi!', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                    // Silinen soruyu listeden çıkarın veya soruları yeniden çekin
                    if (selectedMetinId) {
                        fetchSorular(selectedMetinId);
                    }
                    setSelectedQuestionId('');
                    setQuestionType('');
                    setMultipleChoiceData({
                        question: '',
                        options: [],
                        correctOption: 0,
                    });
                    setOpenEndedData({
                        question: '',
                    });
                } else {
                    return response.json().then((errorData) => {
                        throw new Error(errorData.message || 'Silme işlemi başarısız oldu');
                    });
                }
            })
            .catch((error) => {
                console.error('Error deleting the question:', error);
                toast.error(`Bir hata oluştu: ${error.message}`, {
                    position: 'top-right',
                    autoClose: 5000,
                });
            });
    };

    // Debugging logs
    useEffect(() => {
        console.log('sorular state updated:', sorular);
    }, [sorular]);

    console.log('Component is rendering');
    console.log('sorular:', sorular);

    return (
        <div className="soru-duzenle-paneli">
            <ToastContainer />

            <h1>Soru Düzenle</h1>

            <label htmlFor="metinSelect">Metin Seçiniz:</label>
            <select
                id="metinSelect"
                value={selectedMetinId}
                onChange={handleMetinSelect}
                required
            >
                <option value="">Bir metin seçin</option>
                {metinler.map((metin) => (
                    <option key={metin.id} value={metin.id}>
                        {metin.title}
                    </option>
                ))}
            </select>

            {Array.isArray(sorular) && sorular.length > 0 ? (
                <>
                    <label htmlFor="soruSelect">Soru Seçiniz:</label>
                    <select
                        id="soruSelect"
                        value={selectedQuestionId}
                        onChange={handleQuestionSelect}
                        required
                    >
                        <option value="">Bir soru seçin</option>
                        {sorular.map((soru, index) => (
                            <option key={soru.id || index} value={soru.id}>
                                {soru.question}
                            </option>
                        ))}
                    </select>
                </>
            ) : (
                selectedMetinId && <p>Bu metin için soru bulunamadı.</p>
            )}

            {questionType === 'MULTIPLE_CHOICE' && (
                <div className="multiple-choice-section">
                    <label htmlFor="question">Soru:</label>
                    <input
                        type="text"
                        id="question"
                        name="question"
                        className='question-edit-input'
                        value={multipleChoiceData.question}
                        onChange={handleMultipleChoiceChange}
                        placeholder="Soru metnini girin"
                        required
                    />

                    {multipleChoiceData.options.length > 0 &&
                        multipleChoiceData.options.map((option, index) => (
                            <div key={option.id || index} className="option-container">
                                <input
                                    type="text"
                                    id={`option_${index}`}
                                    name={`option_${index}`}
                                    value={option.optionText}
                                    onChange={(e) => handleOptionTextChange(option.id, e.target.value)}
                                    placeholder={`Şık ${String.fromCharCode(65 + index)}`}
                                    required
                                />
                                <button
                                    onClick={() => handleUpdateOption(option.id, option.optionText, option.correct)}
                                    disabled={!option.id}
                                    className="update-option-button"
                                >
                                    Güncelle
                                </button>
                            </div>
                        ))}

                    <label htmlFor="correctOption">Doğru Şık:</label>
                    <select
                        id="correctOption"
                        name="correctOption"
                        value={multipleChoiceData.correctOption}
                        onChange={handleMultipleChoiceChange}
                    >
                        {multipleChoiceData.options.map((option, index) => (
                            <option key={option.id || index} value={index}>
                                Şık {String.fromCharCode(65 + index)}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {questionType === 'OPEN_ENDED' && (
                <div className="open-ended-section">
                    <label htmlFor="openEndedQuestion">Açık Uçlu Soru:</label>
                    <input
                        type="text"
                        id="openEndedQuestion"
                        name="question"
                        value={openEndedData.question}
                        onChange={handleOpenEndedChange}
                        placeholder="Açık uçlu soru metnini girin"
                        required
                    />
                </div>
            )}

            <button
                className="update-question-button"
                onClick={handleSubmit}
                disabled={!selectedQuestionId}
            >
                Soruyu Güncelle
            </button>

            <button
                className="delete-question-button"
                onClick={() => handleDeleteQuestion(selectedQuestionId)}
                disabled={!selectedQuestionId}
            >
                Soruyu Sil
            </button>

            <button className="question-back-button" onClick={() => window.history.back()}>
                Geri Dön
            </button>
        </div>
    );
};

export default SoruDuzenlePaneli;
