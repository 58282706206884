import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style/MetinPanel.css'; // CSS dosyanızın doğru yolunu kontrol edin

const MetinPanel = () => {
  const navigate = useNavigate();

  // Seviye seçimini yönetir
  const [selectedLevel, setSelectedLevel] = useState(null);

  // Mevcut metinleri tutar
  const [existingMetinler, setExistingMetinler] = useState([]);

  // Seviye seçildiğinde mevcut metinleri fetch eder
  useEffect(() => {
    if (selectedLevel) {
      fetch(`https://ankahizliokuma.com/api/texts/metin/level/${selectedLevel}`)
        .then((response) => response.json())
        .then((data) => setExistingMetinler(data))
        .catch((error) => console.error('Error fetching metinler:', error));
    }
  }, [selectedLevel]);

  // Seviye seçimini yönetir
  const handleLevelSelection = (level) => {
    setSelectedLevel(level);
  };

  // Yönlendirme Fonksiyonları
  const handleNavigateMetin = () => {
    navigate('/admin/87a5a95cb3e25/hızlı-okuma-metin-ekle');
  };

  const handleNavigateMetinEdit = () => {
    navigate('/admin/metin-duzenle/');
  };

  const handleNavigateMetinSil = () => {
    navigate('/admin/metin-sil/');
  };

  const handleNavigateSoru = () => {
    navigate('/admin/soru-ekle/');
  };

  const handleNavigateSoruEdit = () => {
    navigate('/admin/soru-duzenle/');
  };


  const handleNavigateMain = () => {
    navigate('/admin/069847f0e69ca29c8ae7ab9adde7e8eb287a5a95cb3e25b2b68de36e0949b0e4/panel');
  };

  return (
    <div className="metin-panel">
      <h1 className="metin-panel__title">Metin Paneli</h1>

      {/* Seviye Seçimi */}
      {!selectedLevel ? (
        <div className="metin-panel__boxes-container">
          {/* Hızlı Okuma Temel Seviye */}
          <div
            className="metin-panel__box"
            onClick={() => handleLevelSelection('temel')}
          >
            <div className="metin-panel__icons-container">
              <img
                src="/imgs/T-icon.png"
                alt="Hızlı Okuma Temel Seviye"
                className="metin-panel__icon"
              />
              <img
                src="/imgs/S-icon.png"
                alt="S-icon"
                className="metin-panel__icon"
              />
              <img
                src="/imgs/Dot-icon.png"
                alt="Dot-icon"
                className="metin-panel__icon-dot"
              />
            </div>

            <h2 className="metin-panel__box-title">Hızlı Okuma Temel Seviye</h2>
          </div>

          {/* Hızlı Okuma İleri Seviye */}
          <div
            className="metin-panel__box"
            onClick={() => handleLevelSelection('ileri')}
          >
            <div className="metin-panel__icons-ileri-container">
              <img
                src="/imgs/I-icon.png"
                alt="I-icon"
                className="metin-panel__icon"
              />
              <img
                src="/imgs/S-icon.png"
                alt="S-icon"
                className="metin-panel__icon"
              />
              <img
                src="/imgs/Dot-icon.png"
                alt="Dot-icon"
                className="metin-panel__icon-dot"
              />
            </div>

            <h2 className="metin-panel__box-title">Hızlı Okuma İleri Seviye</h2>
          </div>

          {/* Merkez Panele Geri Dön */}
          <div
            className="metin-panel__box metin-panel__box--back"
            onClick={handleNavigateMain}
          >
            <img
              src="/imgs/Back-Icon.png"
              alt="Merkez Panele Geri dön"
              className="metin-panel__box-image"
            />
            <h2 className="metin-panel__box-title">Merkez Panele Geri Dön</h2>
          </div>
        </div>
      ) : (
        <div className="metin-panel__boxes-container">
          {/* Metin Ekle */}
          <div
            className="metin-panel__box"
            onClick={handleNavigateMetin}
          >
            <img
              src="/imgs/Text-add.png"
              alt="Metin Ekle"
              className="metin-panel__box-image"
            />
            <h2 className="metin-panel__box-title">Metin Ekle</h2>
          </div>

          {/* Metin Düzenle */}
          <div
            className="metin-panel__box"
            onClick={handleNavigateMetinEdit}
          >
            <img
              src="/imgs/Text-add.png" // İkonu uygun şekilde değiştirin
              alt="Metin Düzenle"
              className="metin-panel__box-image"
            />
            <h2 className="metin-panel__box-title">Metin Düzenle</h2>
          </div>

          

          {/* Soru Ekle */}
          <div
            className="metin-panel__box"
            onClick={handleNavigateSoru}
          >
            <img
              src="/imgs/Question-add.png"
              alt="Soru Ekle"
              className="metin-panel__box-image"
            />
            <h2 className="metin-panel__box-title">Soru Ekle</h2>
          </div>

          {/* Soru Düzenle */}
          <div
            className="metin-panel__box"
            onClick={handleNavigateSoruEdit} // Muhtemelen farklı bir yönlendirme gerektirir
          >
            <img
              src="/imgs/Question-add.png" // İkonu uygun şekilde değiştirin
              alt="Soru Düzenle"
              className="metin-panel__box-image"
            />
            <h2 className="metin-panel__box-title">Soru Düzenle</h2>
          </div>

          {/* Merkez Panele Geri Dön */}
          <div
            className="metin-panel__box metin-panel__box--back"
            onClick={handleNavigateMain}
          >
            <img
              src="/imgs/Back-Icon.png"
              alt="Merkez Panele Geri dön"
              className="metin-panel__box-image"
            />
            <h2 className="metin-panel__box-title">Merkez Panele Geri Dön</h2>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetinPanel;
