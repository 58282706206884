import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style/soruduzenlepaneli.css';

const SoruDuzenlePaneli = () => {
  const [metinler, setMetinler] = useState([]);
  const [selectedMetinId, setSelectedMetinId] = useState('');
  const [sorular, setSorular] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [multipleChoiceData, setMultipleChoiceData] = useState({
    question: '',
    options: [],
    correctOption: 0,
  });
  const [openEndedData, setOpenEndedData] = useState({
    question: '',
  });

  // Fetch all metinler when component mounts
  useEffect(() => {
    fetch('https://ankahizliokuma.com/api/texts/metin/all')
      .then((response) => response.json())
      .then((data) => setMetinler(data))
      .catch((error) => console.error('Error fetching metinler:', error));
  }, []);

  // Fetch sorular when selectedMetinId changes
  useEffect(() => {
    if (selectedMetinId) {
      fetchSorular(selectedMetinId);
    }
  }, [selectedMetinId]);

  // Fetch sorular function
  const fetchSorular = (metinId) => {
    fetch(`https://ankahizliokuma.com/api/question/metin/${metinId}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched sorular data:', data);
        setSorular(data);
      })
      .catch((error) => console.error('Error fetching sorular:', error));
  };

  // Fetch question details function
  const fetchQuestionDetails = (questionId) => {
    const qId = Number(questionId);
    const selectedQuestion = sorular.find((soru) => soru.id === qId);

    if (selectedQuestion) {
      if (selectedQuestion.type === 'MULTIPLE_CHOICE') {
        fetch(`https://ankahizliokuma.com/api/option/question/${questionId}`)
          .then((response) => response.json())
          .then((optionsData) => {
            console.log('Fetched options data:', optionsData);

            setQuestionType('MULTIPLE_CHOICE');
            setMultipleChoiceData({
              question: selectedQuestion.question,
              options: optionsData.map((option) => ({
                id: option.id,
                optionText: option.optionText,
                correct: option.isCorrect,
              })),
              correctOption: optionsData.findIndex((option) => option.isCorrect),
            });
          })
          .catch((error) => console.error('Error fetching options:', error));
      } else if (selectedQuestion.type === 'OPEN_ENDED') {
        setQuestionType('OPEN_ENDED');
        setOpenEndedData({
          question: selectedQuestion.question,
        });
      }
    } else {
      console.error('Selected question not found in sorular array');
    }
  };

  // Handle metin selection
  const handleMetinSelect = (e) => {
    const metinId = e.target.value;
    setSelectedMetinId(metinId);
    setSelectedQuestionId(''); // Reset selected question
    setQuestionType(''); // Reset question type
    setMultipleChoiceData({
      question: '',
      options: [],
      correctOption: 0,
    });
    setOpenEndedData({
      question: '',
    });
  };

  // Handle question selection
  const handleQuestionSelect = (e) => {
    const questionId = e.target.value;
    setSelectedQuestionId(questionId);
    fetchQuestionDetails(questionId);
  };

  const handleMultipleChoiceChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('option')) {
      const index = parseInt(name.split('_')[1], 10);
      const newOptions = [...multipleChoiceData.options];
      newOptions[index] = {
        ...newOptions[index],
        optionText: value,
      };
      setMultipleChoiceData({ ...multipleChoiceData, options: newOptions });
    } else if (name === 'correctOption') {
      setMultipleChoiceData({ ...multipleChoiceData, correctOption: parseInt(value, 10) });
    } else {
      setMultipleChoiceData({ ...multipleChoiceData, [name]: value });
    }
  };

  // Handle submit
  const handleSubmit = () => {
    const payload =
      questionType === 'MULTIPLE_CHOICE'
        ? {
            question: multipleChoiceData.question,
            options: multipleChoiceData.options.map((option, index) => ({
              id: option.id,
              optionText: option.optionText,
              isCorrect: index === multipleChoiceData.correctOption,
            })),
          }
        : { question: openEndedData.question };

    fetch(`https://ankahizliokuma.com/api/question/update/${selectedQuestionId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(() => {
        toast.success('Soru başarıyla güncellendi!', {
          position: 'top-right',
          autoClose: 3000,
        });
        setMultipleChoiceData({
          question: '',
          options: [],
          correctOption: 0,
        });
        setOpenEndedData({ question: '' });
        setSelectedMetinId('');
        setSelectedQuestionId('');
        setQuestionType('');
        setSorular([]);
      })
      .catch((error) => {
        console.error('Error updating the question:', error);
        toast.error('Bir hata oluştu. Lütfen tekrar deneyin.', {
          position: 'top-right',
          autoClose: 5000,
        });
      });
  };

  // Debugging logs
  useEffect(() => {
    console.log('sorular state updated:', sorular);
  }, [sorular]);

  console.log('Component is rendering');
  console.log('sorular:', sorular);

  return (
    <div className="soru-duzenle-paneli">
      <ToastContainer />

      <h1>Soru Düzenle</h1>

      <label htmlFor="metinSelect">Metin Seçiniz:</label>
      <select
        id="metinSelect"
        value={selectedMetinId}
        onChange={handleMetinSelect}
        required
      >
        <option value="">Bir metin seçin</option>
        {metinler.map((metin) => (
          <option key={metin.id} value={metin.id}>
            {metin.title}
          </option>
        ))}
      </select>

      {Array.isArray(sorular) && sorular.length > 0 ? (
        <>
          <label htmlFor="soruSelect">Soru Seçiniz:</label>
          <select
            id="soruSelect"
            value={selectedQuestionId}
            onChange={handleQuestionSelect}
            required
          >
            <option value="">Bir soru seçin</option>
            {sorular.map((soru, index) => (
              <option key={soru.id || index} value={soru.id}>
                {soru.question}
              </option>
            ))}
          </select>
        </>
      ) : (
        selectedMetinId && <p>Bu metin için soru bulunamadı.</p>
      )}

      {questionType === 'MULTIPLE_CHOICE' && (
        <div className="multiple-choice-section">
          <label htmlFor="question">Soru:</label>
          <input
            type="text"
            id="question"
            name="question"
            value={multipleChoiceData.question}
            onChange={handleMultipleChoiceChange}
            placeholder="Soru metnini girin"
            required
          />

          {multipleChoiceData.options.length > 0 &&
            multipleChoiceData.options.map((option, index) => (
              <div key={index} className="option-container">
                <input
                  type="text"
                  id={`option_${index}`}
                  name={`option_${index}`}
                  value={option.optionText}
                  onChange={handleMultipleChoiceChange}
                  placeholder={`Şık ${String.fromCharCode(65 + index)}`}
                  required
                />
              </div>
            ))}

          <label htmlFor="correctOption">Doğru Şık:</label>
          <select
            id="correctOption"
            name="correctOption"
            value={multipleChoiceData.correctOption}
            onChange={handleMultipleChoiceChange}
          >
            {multipleChoiceData.options.map((option, index) => (
              <option key={index} value={index}>
                Şık {String.fromCharCode(65 + index)}
              </option>
            ))}
          </select>
        </div>
      )}

      {questionType === 'OPEN_ENDED' && (
        <div className="open-ended-section">
          <label htmlFor="openEndedQuestion">Açık Uçlu Soru:</label>
          <input
            type="text"
            id="openEndedQuestion"
            name="question"
            value={openEndedData.question}
            onChange={(e) =>
              setOpenEndedData({ ...openEndedData, question: e.target.value })
            }
            placeholder="Açık uçlu soru metnini girin"
            required
          />
        </div>
      )}

      <button className="update-question-button" onClick={handleSubmit}>
        Soruyu Güncelle
      </button>

      <button className="question-back-button" onClick={() => window.history.back()}>
        Geri Dön
      </button>
    </div>
  );
};

export default SoruDuzenlePaneli;
