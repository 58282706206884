import React, { useState } from 'react';
import './styles/CebirCourseCreater.css'; // CSS dosyanızı buraya ekleyin
const API_CEBIR_URL = "https://cebirmentalaritmetik.com"


const CourseCard = ({ title, date, total,time, students, info }) => (
    <div className="course-card">
        <h3>{title}</h3>
        <p>Tarih: {date}</p>
        <p>Zaman: {time}</p>
        <p>Sınıf Kontejanı: {total}</p>
        <p>Mevcut Kontejan: {students}</p>
        <p>Bilgi: {info}</p>
    </div>
);

const CebirCourseCreator = () => {
    const [courses, setCourses] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [form, setForm] = useState({
        title: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        total: '',
        students: '',
        info: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // POST isteği ile form verilerini backend'e gönder
        try {
            const response = await fetch(`${API_CEBIR_URL}/api/admin/course/cebir/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(form) // Form verilerini JSON formatında gönder
            });
    
            if (response.ok) {
                const newCourse = await response.json(); // Yanıtı JSON olarak al
                setCourses([...courses, newCourse]); // Yeni kursu mevcut listeye ekle
                setForm({
                    title: '',
                    startDate: '',
                    endDate: '',
                    startTime: '',
                    endTime: '',
                    total: '',
                    students: '',
                    info: ''
                });
                setNotificationMessage('Kurs başarıyla eklendi!');
                setNotificationType('success');
            } else {
                setNotificationMessage('Kurs eklenirken bir hata oluştu');
                setNotificationType('error');
            }
        } catch (error) {
            setNotificationMessage('Bir hata oluştu. Lütfen tekrar deneyin.');
            setNotificationType('error');
        } finally {
            setTimeout(() => {
                setNotificationMessage('');
                setNotificationType('');
            }, 3000); // 3 saniye sonra notifikasyonu temizle
        }
    };
    

    return (
        <div className="course-creator">
            <h2>Cebir Kurs Ekle</h2>
            {notificationMessage && (
                <div className={`notification ${notificationType}`}>
                    {notificationMessage}
                </div>
            )}
            <form onSubmit={handleSubmit} className="course-form">
                <label>
                    Grup İsmi:
                    <input
                        type="text"
                        name="title"
                        value={form.title}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Başlangıç Tarihi:
                    <input
                        type="date"
                        name="startDate"
                        value={form.startDate}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Bitiş Tarihi:
                    <input
                        type="date"
                        name="endDate"
                        value={form.endDate}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Başlangıç Saati:
                    <input
                        type="time"
                        name="startTime"
                        value={form.startTime}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Bitiş Saati:
                    <input
                        type="time"
                        name="endTime"
                        value={form.endTime}
                        onChange={handleChange}
                        required
                    />
                </label>
                <label>
                    Sınıf Kontejan
                    <input
                        type="number"
                        name="total"
                        value={form.total}
                        onChange={handleChange}
                        required
                    />
                </label>


                <label>
                    Mevcut Kontejan
                    <input
                        type="number"
                        name="students"
                        value={form.students}
                        onChange={handleChange}
                        required
                    />
                </label>
                
                <label>
                    Bilgi:
                    <textarea
                        name="info"
                        value={form.info}
                        onChange={handleChange}
                        required
                    ></textarea>
                </label>
                <button type="submit">Kurs Ekle</button>
            </form>

            <div className="courses-container">
                {courses.map((course, index) => (
                    <CourseCard
                        key={index}
                        title={course.title}
                        date={`${course.startDate} - ${course.endDate}`}
                        time={`${course.startTime} - ${course.endTime}`}
                        total={course.total}
                        students={course.students}
                        info={course.info}
                    />
                ))}
            </div>
        </div>
    );
};

export default CebirCourseCreator;
