import React, { useState, useEffect } from 'react';
import './home.css';
import Courses from '../CourseCard/Courses';
import { useNavigate } from 'react-router-dom';
import ContactModal from './ContactModal';

const API_URL = process.env.REACT_APP_API_URL;

const Home = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const navigate = useNavigate();

   

    useEffect(() => {
        const checkSession = async () => {
            try {
                const response = await fetch(`${API_URL}/api/check-session`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (response.ok) {
                    // Kullanıcı oturumu varsa Dashboard'a yönlendir
                    navigate('/dashboard');
                }
            } catch (error) {
                console.error('An error occurred while checking session:', error);
                // Hata durumunda ana sayfada kal
            }
        };

        checkSession();
    }, [navigate]);

    const handleSpeedReading = () =>{
        navigate('/hızlı-okuma')
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="home-container">
            <header className="home-header">
                <img className='header-image' src='/imgs/anka-logo-1.png' alt='Anka-icon' />

                <button className='menu-toggle' onClick={toggleMenu}>
                    {isMenuOpen ? '✕' : '☰'}
                </button>

                <nav className={`navbar ${isMenuOpen ? 'open' : ''}`}>
                    <ul className='nav-list'>
                        <li><a href='#home'>Anasayfa</a></li>
                        <li><a href='#about'>Hakkımızda</a></li>
                        <li><a href='#services'>Hizmetler</a></li>
                        <li><a href='#contact'>İletişim</a></li>
                        <li><a href='/login'>Giriş Yap</a></li>
                    </ul>
                </nav>

                <div className='header-info'>
                    <h1>ANKA Hızlı Okuma</h1>
                    <h2>ANKA Hızlı Okuma ile bilgiye kanat açın.</h2>
                    <ContactModal></ContactModal>
                </div>
            </header>

            <div className="boxes-container">
                <div className="box">
                    <img className='internet-icon' src='/imgs/internet-icon.png' alt='canlı-eğitim' />
                    <h2>Canlı Online Eğitimler</h2>
                </div>
                <div className="box">
                    <img onClick={handleSpeedReading} className='book-icon' src='/imgs/book-icon.png' alt='online-eğitim' />
                    <h2 onClick={handleSpeedReading} >Online Hazır Paket Dersler</h2>
                </div>
                <div className="box">
                    <img className='teacher-icon' src='/imgs/teacher-icon.png' alt='yüz-yüze-eğitim' />
                    <h2>Yüz Yüze Eğitimler</h2>
                </div>
            </div>

            <div className="section-title-container">
                <h1>Kurslarımız</h1>
            </div>
            <Courses />

            <div className='contact-us-container'>
                <h1>İletişim</h1>
                <div className='contact-form-wrapper'>
                    <div className='contact-info'>
                     <p><strong>Adres:</strong> Ata Cd. No:86 Onur, 35330 Balçova/İzmir </p>
                        <p><strong>Telefon:</strong> +90 532 429 66 78</p>
                        <p><strong>E-posta:</strong> ankahizliokuma@gmail.com</p>
                        <p><strong>Çalışma Saatleri:</strong> Pazartesi - Cumartesi: 09:30 - 19:40</p>
                    </div>
                    <div className='contact-form'>
                        <h2>Bizimle İletişime Geçin</h2>
                        <form>
                            <div className='form-group'>
                                <label htmlFor='name'>Adınız</label>
                                <input type='text' id='name' name='name' required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='email'>E-posta</label>
                                <input type='email' id='email' name='email' required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='subject'>Konu</label>
                                <input type='text' id='subject' name='subject' required />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='message'>Mesaj</label>
                                <textarea id='message' name='message' rows='4' required></textarea>
                            </div>
                            <button type='submit' className='submit-button'>Mesaj Gönder</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
