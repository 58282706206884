import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Lessons.css'; // CSS dosyamız

const Lessons = () => {
  const navigate = useNavigate();

  const subjects = [
    { name: 'MATEMATİK', id: 'matematik' },
    { name: 'TÜRKÇE', id: 'Turkce' },
    { name: 'FEN BİLİMLERİ', id: 'fen-bilimleri' },
    { name: 'T.C İNKILAP TARİHİ', id: 'tc-inkilap-tarihi' },
    { name: 'OKUL İNGİLİZCESİ', id: 'okul-ingilizcesi' },
  ];

  const handleNavigate = (subjectId) => {
    navigate(`/subject/${subjectId}`);
  };

  return (
    <div className="lessons__container">
      <h1 className="lessons__title">DERSLER</h1>
      <div className="lessons__grid">
        {subjects.map((subject, index) => (
          <button key={index} className="lessons__button" onClick={() => handleNavigate(subject.id)}>
            <span className="lessons__text">{subject.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Lessons;
